import React, { useEffect, useState } from 'react';
import FullInterviewInformation from '../../types/FullInterviewInformation';
import Modal from 'react-modal';
import Button from './Button';
import axios from 'axios';

interface CandidateDetailsProps {
  data: FullInterviewInformation;
}

const CandidateDetails: React.FC<CandidateDetailsProps> = ({ data }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({ videoUrl: '', captionsUrl: '' });
  const [isVideoProcessing, setIsVideoProcessing] = useState(false);
  const [captionSrc, setCaptionSrc] = useState('');

  const handleViewVideo = (videoLink: string, captionsLink: string) => {
    setIsVideoModalOpen(true);
    setIsVideoProcessing(true);
    setCurrentVideo({ videoUrl: videoLink, captionsUrl: captionsLink });
    setIsVideoProcessing(false);
  };

  useEffect(() => {
    let vttBlobUrl: string;

    if (!currentVideo.captionsUrl) {
      console.log('No captions URL');
      return;
    }

    const getCaptions = async () => {
      try {
        const proxyEndpoint = `/api/get-vtt?url=${encodeURIComponent(
          currentVideo.captionsUrl
        )}`;

        // Fetch the VTT file via the proxy
        const response = await axios.get(proxyEndpoint, {
          responseType: 'blob',
        });

        // Create a Blob URL
        const vttBlob = new Blob([response.data], { type: 'text/vtt' });
        vttBlobUrl = URL.createObjectURL(vttBlob);

        // Set the caption source in state
        setCaptionSrc(vttBlobUrl);
      } catch (error) {
        console.error('Error fetching VTT:', error);
      }
    };

    getCaptions();

    return () => {
      // Cleanup
      if (vttBlobUrl) {
        URL.revokeObjectURL(vttBlobUrl);
      }
      // Clear the caption source
      setCaptionSrc('');
    };
  }, [currentVideo]);

  // Prepare point breakdown data
  const pointBreakdown = data.pointBreakdown || {};

  // Initialize totals
  let totalTestPoints = 0;
  let totalTestMaxPoints = 0;

  // Generate candidate answers with points
  const candidateAnswers = Object.keys(data.answers || {}).length > 0
    ? Object.entries(data.answers).map(([questionId, parts]) => {
        const questionPointData = pointBreakdown[questionId] || {};
        let questionTotalPoints = 0;
        let questionMaxPoints = 0;

        const partsElements = Object.entries(parts).map(([partId, answer]) => {
          const pointData = questionPointData[partId];
          const points = pointData ? pointData.points : 0;
          const maxPoints = pointData ? pointData.maxPoints : 0;

          questionTotalPoints += points;
          questionMaxPoints += maxPoints;

          return (
            <div key={partId} className="text-sm mb-2">
              <p>
                <span className="font-bold">Part {partId}:</span> {answer.value}
              </p>
              {pointData && (
                <p className="text-gray-600">
                  Points: {points}/{maxPoints}
                </p>
              )}
            </div>
          );
        });

        // Update total test points
        totalTestPoints += questionTotalPoints;
        totalTestMaxPoints += questionMaxPoints;

        return (
          <div key={questionId} className="border-b border-gray-300 pb-4">
            <p className="text-sm font-bold">Question {questionId}:</p>
            <div className="pl-4">
              {partsElements}
              <p className="text-sm font-bold mt-2">
                Total for Question {questionId}: {questionTotalPoints}/{questionMaxPoints} points
              </p>
            </div>
          </div>
        );
      })
    : null;

  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <h4 className="text-lg font-semibold mb-4">Interview Details</h4>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <p className="text-sm">
            <span className="font-bold">First Name:</span> {data.firstName}
          </p>
          <p className="text-sm">
            <span className="font-bold">Last Name:</span> {data.lastName}
          </p>
          <p className="text-sm">
            <span className="font-bold">Email:</span> {data.email}
          </p>
          <p className="text-sm">
            <span className="font-bold">Candidate ID:</span> {data.id.slice(0, 8)}
          </p>
          <p className="text-sm">
            <span className="font-bold">Role Name:</span> {data.roleName}
          </p>
          <p className="text-sm">
            <span className="font-bold">Company Name:</span> {data.companyName}
          </p>
          <p className="text-sm">
            <span className="font-bold">Deadline:</span>{' '}
            {new Date(data.interviewDeadline).toLocaleString([], {
              hour: '2-digit',
              minute: '2-digit',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
          </p>
          <p className="text-sm">
            <span className="font-bold">Date Assigned:</span>{' '}
            {new Date(data.dateAssigned).toLocaleString([], {
              hour: '2-digit',
              minute: '2-digit',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
          </p>
          <p className="text-sm">
            <span className="font-bold">Interview Instructions:</span>{' '}
            {data.interviewInstructions}
          </p>
        </div>
        <div>
          <p className="text-sm">
            <span className="font-bold">Test Status:</span> {data.testStatus}
          </p>
          <p className="text-sm">
            <span className="font-bold">Group Name:</span> {data.groupName}
          </p>
          <p className="text-sm">
            <span className="font-bold">Labels:</span>{' '}
            {data.labels && data.labels.length > 0 ? data.labels.join(', ') : 'None'}
          </p>
          <p className="text-sm">
            <span className="font-bold">Score:</span> {data.score || 'N/A'}
          </p>
          <p className="text-sm">
            <span className="font-bold">Performance Summary:</span>{' '}
            {data.performanceSummary || 'N/A'}
          </p>
        </div>
      </div>

      <h5 className="text-lg font-semibold mt-6 mb-4">Candidate Answers</h5>
      <div className="space-y-4">
        {candidateAnswers ? (
          <>
            {candidateAnswers}
            {totalTestMaxPoints > 0 && (
              <p className="text-lg font-bold mt-4">
                Total Test Score: {totalTestPoints}/{totalTestMaxPoints} points
              </p>
            )}
          </>
        ) : (
          <p className="text-sm text-gray-500">No answers available.</p>
        )}
      </div>

      <h5 className="text-lg font-semibold mt-6 mb-4">Assigned Tests</h5>
      <div className="space-y-4">
        {data.assignedQuestions && data.assignedQuestions.length > 0 ? (
          data.assignedQuestions.map((test, index) => (
            <div key={index} className="flex items-center justify-between">
              <span className="text-sm font-medium">Question {index + 1}</span>
              {test.spreadsheetLink ? (
                <Button
                  onClick={() => window.open(test.spreadsheetLink, '_blank')}
                  className="font-bold"
                >
                  View Candidate's Answers
                </Button>
              ) : (
                <p className="text-sm text-gray-500">No test available.</p>
              )}
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500">No tests assigned.</p>
        )}
      </div>

      <div className="flex space-x-6 mt-6">
        {data.finalVideoUrl ? (
          <Button
            onClick={() => handleViewVideo(data.finalVideoUrl, data.captionsUrl)}
            className="font-bold"
            inverted
          >
            View Video
          </Button>
        ) : (
          <p className="text-sm text-gray-500">No video available.</p>
        )}
      </div>

      {/* Video Modal */}
      <Modal
        isOpen={isVideoModalOpen}
        onRequestClose={() => setIsVideoModalOpen(false)}
        contentLabel="View Video"
        ariaHideApp={false}
        style={{
          content: {
            top: '10%',
            left: '20%',
            right: '20%',
            bottom: '10%',
            padding: '2%',
            border: 'none',
            background: 'white',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <div className="relative w-full">
          <h2 className="text-xl font-bold mb-4">Candidate Video</h2>

          {isVideoProcessing ? (
            <div className="flex items-center justify-center w-full h-full">
              <p>Processing video...</p>
            </div>
          ) : currentVideo.videoUrl ? (
            <video
              controls
              src={currentVideo.videoUrl}
              className="w-full h-full"
              style={{ objectFit: 'contain' }}
            >
              <track src={captionSrc} kind="subtitles" srcLang="en" label="English" />
            </video>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p>Loading video...</p>
            </div>
          )}
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsVideoModalOpen(false)}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CandidateDetails;
