import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { ref, uploadBytes } from 'firebase/storage';
import Modal from 'react-modal';
import {
  arrayUnion, doc, getDoc, updateDoc,
} from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index';
// import { instructions } from '../utils/conversation_config.js';
// import { WavRenderer } from '../utils/wav_renderer';

import SpreadsheetQuestion from '../components/SpreadsheetQuestion';
import QuestionList from '../components/QuestionList';

import { storage, db } from '../firebase'; // Import Firebase storage

import './Interview.scss';
import Candidate from '../../types/Interview';

interface Props {
  audioStream: MediaStream | null;
  videoStream: MediaStream | null;
  screenStream: MediaStream | null;
}

const LOCAL_RELAY_SERVER_URL = 'http://localhost:8082';

interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

const Interview: React.FC<Props> = ({
  audioStream, videoStream, screenStream,
}) => {
  const { interviewId = '' } = useParams<{ interviewId: string }>();
  const navigate = useNavigate();


  // if any of the streams are null, navigate back to the dashboard
  if (!audioStream || !videoStream || !screenStream) {
    if (interviewId) {
      navigate(`/permissions/${interviewId}`);
    } else {
      navigate('/dashboard');
    }
  }

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 }),
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 }),
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      url: LOCAL_RELAY_SERVER_URL,
    }),
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());
  // Refs to store the media recorders and recorded chunks
  const webcamRecorderRef = useRef<MediaRecorder | null>(null);
  const screenRecorderRef = useRef<MediaRecorder | null>(null);

  const webcamChunksRef = useRef<Blob[]>([]);
  const screenChunksRef = useRef<Blob[]>([]);

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [currentPage, setCurrentPage] = useState<string>('questionList');
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const timeOfLastCodeSendRef = useRef(Date.now());
  const timeOfLastCellSendRef = useRef(Date.now());
  const [interviewData, setInterviewData] = useState<Candidate>();
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [showTimesUpModal, setShowTimesUpModal] = useState(false);
  const [showConfirmEndInterviewModal, setShowConfirmEndInterviewModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [timeLeftDisplay, setTimeLeftDisplay] = useState('');



  // const initialCode = `# ${codingQuestion}\n\n# Write your code here`;
  const [code, setCode] = useState('');
  const [sheetData, setSheetData] = useState<any[]>([]);

  const prevCodeRef = useRef('');
  const prevCellRef = useRef('[]');
  const codeRef = useRef('');
  const sheetRef = useRef<any[]>([]);
  const uploadPromisesRef = useRef<Promise<void>[]>([]);


  /**
   * Fetch candidate data from Firebase
   */
  useEffect(() => {
    const fetchInterviewData = async () => {
      if (interviewId) {
        try {
          const docRef = doc(db, 'interviews', interviewId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data() as Candidate;
            setInterviewData(data);
          } else {
            console.log('No such document!');
          }
        } catch (e) {
          console.error('Error fetching interview data:', e);
          navigate('/dashboard');
        }
      } else {
        navigate('/dashboard');
      }
    };
    fetchInterviewData();
  }, [interviewId]);

  /**
   * Set current page and connect conversation when candidate data is available
   */
  useEffect(() => {
    if (interviewData) {
      // get current time and store that in the database
      if (
        !interviewData.interviewStartTime
        || interviewData.interviewStartTime === 0) {
        const currentTime = new Date().getTime();
        const interviewRef = doc(db, 'interviews', interviewId);
        updateDoc(interviewRef, {
          interviewStartTime: currentTime,
          testStatus: 'In Progress',
        });
        setInterviewData((prevData) => {
          if (prevData) {
            const updatedData = { ...prevData };
            updatedData.interviewStartTime = currentTime;
            updatedData.testStatus = 'In Progress';
            return updatedData;
          }
          return prevData;
        });
      } 
    }
  }, [interviewData]);

  useEffect(() => {
    if (interviewData) {
      const intervalId = setInterval(() => {
        setTimeLeftDisplay(getTimeLeft());
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [interviewData]);

  /**
   * Initialize code or sheet data based on the question type
   */
  // useEffect(() => {
  //   if (InterviewData) {
  //     if (InterviewData.assignedQuestionType === 'codingQuestion') {
  //       const initialCode = `# ${InterviewData.assignedQuestionContent}\n\n# Write your code here`;
  //       setCode(initialCode);
  //       prevCodeRef.current = initialCode;
  //     } else if (InterviewData.assignedQuestionType === 'financialQuestion') {
  //       // Initialize sheet data if needed
  //       const initialSheetData = InterviewData.initialSheetData || [];
  //       setSheetData(initialSheetData);
  //       prevCellRef.current = JSON.stringify(initialSheetData);
  //     }
  //   }
  // }, [InterviewData]);


  // Whenever code changes, update the ref
  useEffect(() => {
    codeRef.current = code;
  }, [code]);

  // Whenever sheet data changes, update the ref
  useEffect(() => {
    sheetRef.current = sheetData;
  }, [sheetData]);

  // every 5 minutes, stop and start the recording
  useEffect(() => {
    if (audioStream && videoStream && screenStream) {
      startRecording();
      recordingTimerRef.current = setInterval(() => {
        stopRecording().catch(error => {
          console.error('Error stopping recording:', error);
        });
        startRecording();
      }, 300000); // 5 minutes in milliseconds
  
      return () => {
        if (recordingTimerRef.current) {
          clearInterval(recordingTimerRef.current);
          recordingTimerRef.current = null;
        }
        console.log('Recording timer cleared');
  
        stopRecording()
          .then(() => {
            return Promise.all(uploadPromisesRef.current);
          })
          .then(() => {
            console.log('All uploads completed on unmount.');
          })
          .catch((error) => {
            console.error('Error during uploads on unmount:', error);
          });
      };
    }
  }, [audioStream, videoStream, screenStream]);
  
  

  /**
   * Format time as hh:mm:ss
   */
  const getTimeLeft = () => {
    if (!interviewData.interviewStartTime) return '00:00:00';
    const currentTime = new Date().getTime();
    const timePassed = Math.floor(
      (currentTime - interviewData.interviewStartTime) / 1000,
    );
    const remainingTime = interviewData.timeLimitMinutes * 60 - timePassed;

    if (remainingTime <= 0) {
      setShowTimesUpModal(true);
      return '00:00:00';
    }
    
      
    const h = Math.floor(remainingTime / 3600)
      .toString()
      .padStart(2, '0');
    const m = Math.floor((remainingTime % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const s = (remainingTime % 60).toString().padStart(2, '0');
    return `${h}:${m}:${s}`;
  };



  const handleWebcamStop = async () => {
    if (webcamChunksRef.current.length === 0) return;

    const mimeType = 'video/webm';
  
    const blob = new Blob(webcamChunksRef.current, { type: mimeType });
    const filePath = `recordings/${interviewId}-webcam-${Date.now()}.webm`;
    const storageRef = ref(storage, filePath);
  
    const uploadPromise = uploadBytes(storageRef, blob)
      .then(() => {
        console.log('Webcam recording uploaded to Firebase Storage.');
        return updateDoc(doc(db, 'interviews', interviewId), {
          webcamLinks: arrayUnion(filePath),
        });
      })
      .catch((error) => {
        console.error('Error uploading webcam recording:', error);
        setErrorMessage('Failed to upload webcam recording.');
        setShowErrorModal(true);
        throw error;
      })
      .finally(() => {
        webcamChunksRef.current = []; // Clear chunks after upload
      });
  
    uploadPromisesRef.current.push(uploadPromise);
  };
  
  const handleScreenStop = async () => {
    if (screenChunksRef.current.length === 0) return;
    const mimeType = 'video/webm';
  
    const blob = new Blob(screenChunksRef.current, { type: mimeType });
    const filePath = `recordings/${interviewId}-screen-${Date.now()}.webm`;
    const storageRef = ref(storage, filePath);
  
    const uploadPromise = uploadBytes(storageRef, blob)
      .then(() => {
        console.log('Screen recording uploaded to Firebase Storage.');
        return updateDoc(doc(db, 'interviews', interviewId), {
          screenLinks: arrayUnion(filePath),
        });
      })
      .catch((error) => {
        console.error('Error uploading screen recording:', error);
        setErrorMessage('Failed to upload screen recording.');
        setShowErrorModal(true);
        throw error;
      })
      .finally(() => {
        screenChunksRef.current = []; // Clear chunks after upload
      });
  
    uploadPromisesRef.current.push(uploadPromise);
  };
  

  /**
   * Start recording function
   */
  const startRecording = async () => {
    console.log('Starting recording');
    if (audioStream && videoStream && screenStream) {
      const audioTracks = audioStream.getAudioTracks();
      const webcamVideoTracks = videoStream.getVideoTracks();
      const screenVideoTracks = screenStream.getVideoTracks();
  
      // Create MediaStream for webcam video with audio
      const webcamStream = new MediaStream([...webcamVideoTracks, ...audioTracks]);
  
      // Create MediaStream for screen share (without audio)
      const screenStreamOnly = new MediaStream([...screenVideoTracks]);
  
      // Initialize MediaRecorder options
      let mimeType = 'video/webm'; // Default MIME type
      if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
        mimeType = 'video/webm; codecs=vp9';
      } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp8')) {
        mimeType = 'video/webm; codecs=vp8';
      } else if (MediaRecorder.isTypeSupported('video/webm')) {
        mimeType = 'video/webm';
      } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        mimeType = 'video/mp4';
      } else {
        console.error('No supported mimeType found for MediaRecorder');
        return;
      }
  
      const options = {
        mimeType,
        videoBitsPerSecond: 500000, // Adjust as needed
        audioBitsPerSecond: 64000,  // Adjust as needed
      };
  
      // Initialize MediaRecorders
      let webcamRecorder;
      try {
        webcamRecorder = new MediaRecorder(webcamStream, options);
      } catch (e) {
        console.error('Error initializing MediaRecorder for webcam:', e);
        return;
      }
  
      let screenRecorder;
      try {
        screenRecorder = new MediaRecorder(screenStreamOnly, options);
      } catch (e) {
        console.error('Error initializing MediaRecorder for screen share:', e);
        return;
      }
  
      webcamRecorderRef.current = webcamRecorder;
      screenRecorderRef.current = screenRecorder;
  
      // Handle dataavailable events
      webcamRecorder.addEventListener('dataavailable', (event) => {
        if (event.data.size > 1024) { // Threshold of 1KB
          webcamChunksRef.current.push(event.data);
        }
      });
  
      screenRecorder.addEventListener('dataavailable', (event) => {
        if (event.data.size > 1024) { // Threshold of 1KB
          screenChunksRef.current.push(event.data);
        }
      });
  
      // Attach stop event listeners
      webcamRecorder.addEventListener('stop', handleWebcamStop);
      screenRecorder.addEventListener('stop', handleScreenStop);
  
      // Start recording
      try {
        webcamRecorder.start();
        console.log('Webcam recording started');
      } catch (e) {
        console.error('Error starting webcam MediaRecorder:', e);
      }
  
      try {
        screenRecorder.start();
        console.log('Screen recording started');
      } catch (e) {
        console.error('Error starting screen MediaRecorder:', e);
      }
    } else {
      console.error('Audio, video, or screen stream is not available.');
    }
  };
  

  /**
   * Stop recording function
   */
  const stopRecording = () => {
    return new Promise<void>((resolve, reject) => {
      let stoppedCount = 0;
  
      const handleStop = () => {
        stoppedCount += 1;
        if (stoppedCount === 2) { // Both webcam and screen have stopped
          resolve();
        }
      };
  
      if (webcamRecorderRef.current && webcamRecorderRef.current.state !== 'inactive') {
        webcamRecorderRef.current.addEventListener('stop', handleStop, { once: true });
        webcamRecorderRef.current.stop();
        console.log('Webcam recording stopped');
      } else {
        stoppedCount += 1;
      }
  
      if (screenRecorderRef.current && screenRecorderRef.current.state !== 'inactive') {
        screenRecorderRef.current.addEventListener('stop', handleStop, { once: true });
        screenRecorderRef.current.stop();
        console.log('Screen recording stopped');
      } else {
        stoppedCount += 1;
      }
  
      // If both recorders are already inactive
      if (stoppedCount === 2) {
        resolve();
      }
    });
  };
  

  /**
   * Connect to conversation:
   * WavRecorder takes speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(
    async () => {
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;
      client.updateSession({
        turn_detection: { type: 'server_vad' },
      });

      // Set state variables
      startTimeRef.current = new Date().toISOString();
      setRealtimeEvents([]);
      setItems(client.conversation.getItems());

      // Connect to microphone
      await wavRecorder.begin();

      // Connect to audio output
      await wavStreamPlayer.connect();

      // Connect to realtime API
      await client.connect();

      // if (interviewData) {
      //   const questionContent = interviewData.assignedQuestions[questionIndex].question

      //   // Send the assigned question content to the client
      //   client.sendUserMessageContent([
      //     {
      //       type: 'input_text',
      //       text: questionContent,
      //     },
      //   ]);
      // } else {
      //   throw new Error('Interview data not available');
      // }

      if (client.getTurnDetectionType() === 'server_vad') {
        await wavRecorder.record((data) => { return client.appendInputAudio(data.mono); });
      }
    },
    [audioStream, videoStream, screenStream],
  );

  const startInterview = async () => {
    try {
      await connectConversation();
    } catch (error) {
      console.error('Error connecting to conversation:', error);
    }
  };

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setRealtimeEvents([]);
    setItems([]);

    try {
      const client = clientRef.current;
      client.disconnect();
    } catch (error) {
      console.error('Error disconnecting conversation:', error);
    }

    try {
      const wavRecorder = wavRecorderRef.current;
      await wavRecorder.end();

      const wavStreamPlayer = wavStreamPlayerRef.current;
      await wavStreamPlayer.interrupt();
    } catch (error) {
      console.error('Error disconnecting audio:', error);
    }
  }, []);

  /**
   * Handle code changes from the coding question page
   */
  const handleCodeChange = (text: string) => {
    if (Date.now() - timeOfLastCodeSendRef.current > 5000
    && text !== prevCodeRef.current
  ) {
      prevCodeRef.current = text;
      timeOfLastCodeSendRef.current = Date.now();
      console.log('Sending code to server');
      console.log(text);
      // Send the code text to the client
      const client = clientRef.current;
      client.sendUserMessageContent([
        {
          type: 'input_text',
          text,
        },
      ]);
    }
  };

  const handleCellChange = (data) => {
    const dataString = JSON.stringify(data);

    if (dataString !== prevCellRef.current) {
      console.log('Data changed:', dataString);
    }

    if (
      Date.now() - timeOfLastCellSendRef.current > 5000
      && dataString !== prevCellRef.current
    ) {
      prevCellRef.current = dataString;
      timeOfLastCellSendRef.current = Date.now();
      const client = clientRef.current;
      client.sendUserMessageContent([
        {
          type: 'input_text',
          text: dataString,
        },
      ]);
    }
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const { scrollHeight } = eventsEl;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]'),
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events, and more
   */
  // useEffect(() => {
  //   console.log('Audio client setup started');
  //   // Get refs
  //   const wavStreamPlayer = wavStreamPlayerRef.current;
  //   const client = clientRef.current;

  //   // Set instructions
  //   client.updateSession({ instructions: instructions });
  //   // Set transcription, otherwise we don't get user transcriptions back
  //   client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

  //   console.log('Instructions set and transcription enabled:', instructions);

  //   // Handle realtime events from client + server for event logging
  //   client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
  //     setRealtimeEvents((realtimeEvents) => {
  //       const lastEvent = realtimeEvents[realtimeEvents.length - 1];
  //       if (lastEvent?.event.type === realtimeEvent.event.type) {
  //         // If we receive multiple events in a row, aggregate them for display purposes
  //         lastEvent.count = (lastEvent.count || 0) + 1;
  //         return realtimeEvents.slice(0, -1).concat(lastEvent);
  //       } else {
  //         return realtimeEvents.concat(realtimeEvent);
  //       }
  //     });
  //   });
  //   client.on('error', (event: any) => console.error(event));
  //   client.on('conversation.interrupted', async () => {
  //     console.log('Conversation interrupted');
  //     const trackSampleOffset = await wavStreamPlayer.interrupt();
  //     if (trackSampleOffset?.trackId) {
  //       const { trackId, offset } = trackSampleOffset;
  //       await client.cancelResponse(trackId, offset);
  //     }
  //   });
  //   client.on('conversation.updated', async ({ item, delta }: any) => {
  //     if (item.role === 'user') {
  //       handleCodeChange(codeRef.current);
  //       handleCellChange(sheetRef.current);
  //     }

  //     const items = client.conversation.getItems();
  //     if (delta?.audio) {
  //       wavStreamPlayer.add16BitPCM(delta.audio, item.id);
  //     }
  //     if (item.status === 'completed' && item.formatted.audio?.length) {
  //       const wavFile = await WavRecorder.decode(
  //         item.formatted.audio,
  //         24000,
  //         24000
  //       );
  //       item.formatted.file = wavFile;
  //     }
  //     setItems(items);
  //   });

  //   setItems(client.conversation.getItems());

  //   return () => {
  //     // Cleanup; resets to defaults
  //     client.reset();
  //   };
  // }, []);

  const stopSharing = () => {
    // stop webcam, audio, and screen sharing
    if (audioStream) {
      audioStream.getTracks().forEach((track) => { return track.stop(); });
    }
    if (videoStream) {
      videoStream.getTracks().forEach((track) => { return track.stop(); });
    }
    if (screenStream) {
      screenStream.getTracks().forEach((track) => { return track.stop(); });
    }
  };

  const sanitizeAnswers = (answers: {
    [key: string]: { [key: string]: string | number | undefined };
  }): {
    [key: string]: { [key: string]: string | number | null };
  } => {
    const sanitizedAnswers: { [key: string]: { [key: string]: string | number | null } } = {};
  
    Object.keys(answers).forEach((key) => {
      const subObject = answers[key];
      sanitizedAnswers[key] = {};
  
      Object.keys(subObject).forEach((subKey) => {
        const value = subObject[subKey];
        sanitizedAnswers[key][subKey] = value !== undefined ? value : null; // Replace undefined with null
      });
    });
  
    return sanitizedAnswers;
  };

  const onSaveAnswers = async (answers: { [key: string]: { value: any; formula: any } }, questionNumber: number) => {
    console.log('Saving answers:', answers);

    const answersWithUndefinedReplacedWithNull = sanitizeAnswers(answers);

    const interviewRef = doc(db, 'interviews', interviewId);
    await updateDoc(interviewRef, {
      [`answers.${questionNumber}`]: answersWithUndefinedReplacedWithNull,
    });
    setInterviewData((prevData) => {
      if (prevData) {
        const updatedData = { ...prevData };
        updatedData.answers[questionNumber] = answers;
        return updatedData;
      }
      return prevData;
    });
  };
  

  /**
   * Handle end of the interview
   */
  const handleEndInterview = async () => {
    try {
      setIsUploading(true); // Start uploading indicator
  
      // 1. Clear the recording timer to prevent further stopRecording calls
      if (recordingTimerRef.current) {
        clearInterval(recordingTimerRef.current);
        recordingTimerRef.current = null;
      }
  
      // 2. Stop the recordings and wait for them to finish
      await stopRecording();
  
      // 3. Wait for all ongoing uploads to complete
      await Promise.all(uploadPromisesRef.current);
      // Clear the uploadPromisesRef after all uploads are done
      uploadPromisesRef.current = [];
  
      // 4. Update the interview status to 'Completed'
      const interviewRef = doc(db, 'interviews', interviewId);
      await updateDoc(interviewRef, {
        testStatus: 'Completed',
        interviewEndTime: new Date().getTime(),
      });

      stopSharing();
  
      // 5. Cleanup and navigate back
      disconnectConversation();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error ending interview:', error);
      setErrorMessage('An error occurred while ending the interview. Please try again.');
      setShowErrorModal(true);
    } finally {
      setIsUploading(false); // Stop uploading indicator
    }
  };
  
  

  // Ensure candidate data is loaded before rendering
  if (!interviewData) {
    return <div>Loading...</div>;
  }

  /**
   * Render the application
   */
  return (
    <div>

    {isUploading && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
          <svg
            className="animate-spin h-10 w-10 text-gray-800 mb-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            />
          </svg>
          <p className="text-lg text-gray-800">Submitting test and recordings... Do not leave this page.</p>
        </div>
      </div>
    )}


      <Modal
        isOpen={showErrorModal}
        onRequestClose={() => setShowErrorModal(false)}
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Upload Error</h2>
          <p className="text-lg text-gray-600 mb-4">{errorMessage}</p>
          <button
            type="button"
            onClick={() => setShowErrorModal(false)}
            className="py-1 px-4 bg-red-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-red-700 transition duration-300"
          >
            Close
          </button>
        </div>
      </Modal>


      <Modal
        isOpen={showTimesUpModal}
        onRequestClose={() => { return setShowTimesUpModal(false); }}
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Time&apos;s Up!
          </h2>
          <p className="text-lg text-gray-600 mb-4">Your interview has ended.</p>
          <button
            type="button"
            onClick={() => {
              setShowTimesUpModal(false);
              handleEndInterview();
            }}
            className="py-1 px-4 bg-red-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-red-700 transition duration-300"
          >
            Submit and End Interview
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showConfirmEndInterviewModal}
        onRequestClose={() => {
          return setShowConfirmEndInterviewModal(false);
        }}
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Submit and End Interview?</h2>
          <p className="text-lg text-gray-600 mb-4">Are you sure you want to submit and end the interview?</p>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => { return setShowConfirmEndInterviewModal(false); }}
              className="py-1 px-4 bg-gray-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-gray-700 transition duration-300 mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                setShowConfirmEndInterviewModal(false);
                handleEndInterview();
              }}
              className="py-1 px-4 bg-red-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-red-700 transition duration-300"
            >
              End Interview
            </button>
          </div>
        </div>
      </Modal>

      {/* Timer displayed at the top right */}
      <div className="fixed top-0 right-0 m-2 py-1 px-4 bg-gray-800 text-white text-lg font-semibold rounded-lg shadow-md z-50">
        Time Left:
        {' '}
        {timeLeftDisplay}
      </div>
      { currentPage === 'questionList'
      && (
      <button
        type="button"
        onClick={() => {
          setShowConfirmEndInterviewModal(true);
        }}
        className="fixed m-2 py-1 px-4 bg-red-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-red-700 transition duration-300"
      >
        Submit and End Interview
      </button>
      )}

      {currentPage === 'questionList' && (
      <QuestionList
        interviewData={interviewData}
        onSelectQuestion={(qIndex) => {
          setCurrentPage('question');
          setQuestionIndex(qIndex);
          startInterview();
        }}
      />
      )}

      {currentPage === 'question'
      && interviewData.assignedQuestions[questionIndex].questionType === 'Spreadsheet' && (
        <SpreadsheetQuestion
          questionParts={interviewData.assignedQuestions[questionIndex].questionParts}
          sheetLink={
          interviewData.assignedQuestions[questionIndex].spreadsheetLink
        }
          questionInstruction={interviewData.assignedQuestions[questionIndex].questionInstruction}
          questionAssumptions={interviewData.assignedQuestions[questionIndex].questionAssumptions}
          onBack={() => {
            disconnectConversation();
            setCurrentPage('questionList');
          }}
          onSaveAnswers={(answers) => {
            onSaveAnswers(answers, interviewData.assignedQuestions[questionIndex].questionNumber);
          }}
          initialAnswers={interviewData.answers[interviewData.assignedQuestions[questionIndex].questionNumber] || {}}
        />
      )}
    </div>
  );
};

export default Interview;
