// src/pages/ForgotPassword.tsx
import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Button from '../components/Button';
import NavBar from '../components/NavBar';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user]);

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Check your inbox.');
    } catch (error: any) {
      console.error('Error sending password reset email:', error);
      setMessage('Error sending password reset email. Please try again.');
    }
  };

  return (
    <div>
      <NavBar />
    <div className="flex items-center justify-center h-screen">
      <form
        className="bg-white p-10 rounded-md shadow-lg max-w-sm w-full border border-gray-200"
      >
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          Reset Password
        </h2>
        {message && (
          <div className="mb-4 text-center text-green-600 font-semibold">
            {message}
          </div>
        )}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold">Email</label>
          <input
            type="email"
            className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
            placeholder="Enter your registered email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <Button
          onClick={handlePasswordReset}
          className="w-full font-bold"
        >
          Send Reset Email
        </Button>
        <p
          className="text-sm font-bold text-gray-600 mt-4 text-center cursor-pointer"
          onClick={() => navigate('/login')}
        >
          Back to Login
        </p>
      </form>
    </div>
    </div>
  );
};

export default ForgotPassword;
