
import React from 'react';
import Interview from '../../types/Interview';
import Button from './Button';

function QuestionList({
  onSelectQuestion,
  interviewData
}: {
  onSelectQuestion: (questionIndex: number) => void;
  interviewData: Interview;
}) {
  return (
    <div className="question-list-page flex items-center justify-center h-screen">
      <div className="text-center">
      <h2 className="text-4xl font-bold mb-8">Assigned Questions</h2>
      <div className="space-y-4 flex flex-col items-center">
       
       {
        interviewData.assignedQuestions.map((question, index) => {
          return (
            <Button
            key={index}
            onClick={() => onSelectQuestion(index)}
            className="w-full max-w-md py-4 text-2xl font-semibold"
            inverted
            >
            {question.questionTitle}
            </Button>
          )
        })
       }
      
      </div>
      </div>
    </div>
  );
}

export default QuestionList;
