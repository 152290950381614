import Question from "../../types/Question";

const LBO: Question[] = [
    {
        questionNumber: 1,
        questionType: 'Spreadsheet',
        spreadsheetLink: "https://docs.google.com/spreadsheets/d/1kPVJHOiokbR4FJYkUGv_A2MkKnVD0r72xu8glrawZR8/edit?usp=sharing",
        questionInstruction: "Welcome to your Paper LBO Test. Our firm is engaging in the purchase of FactoryCo. They would like your help determining if this is a strong investment. We consider this to be a strong candidate for investment if the opportunity represents an IRR of above 20%. Please complete the following paper LBO model and at the bottom of the sheet calculate IRR.",
        questionAssumptions: "The sellor negotiates for a price of 6.0x Forward 12 months EBITDA at the end of year 0 with a weighted average interest rate on debt of 10%. The debt-to-equity ratio for the LBO acquisition will be 60:40. FactoryCo expects to reach $200 million in sales revenue with an EBITDA margin of 30% in Year 1. Revenue is expected to increase by 12% year-over-year with constant EBITDA margins. EBITDA margins will remain constant. FactoryCo's capital expenditures are expected to equal 10% of sales each year. Operating working capital is expected to increase by $5 million each year whilst depreciation is flat $20mm a year. The corporate tax rate is 40%. Ninth Street Capital Partners sells FactoryCo after Year 5 at a 6x EBITDA multiple. All debt is paid down at the moment of sale.  The model below is your template. Use this template. Cells are unlinked. Levered Free Cash Flows pay down the principal.",
        questionParts: [
            {
                id: 1,
                text: "Entry Multiple",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C5',
                cellType: 'single',
            },
            {
                id: 2,
                text: "EBITDA (Y1)",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C6',
                cellType: 'single',
            },
            {
                id: 3,
                text: "Price Paid",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C7',
                cellType: 'single',
            },
            {
                id: 4,
                text: "Debt",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C9',
                cellType: 'single',
            },
            {
                id: 5,
                text: "Equity",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C10',
                cellType: 'single',
            },
            {
                id: 6,
                text: "Total",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C11',
                cellType: 'single',
            },
            {
                id: 7,
                text: "Sales Revenue",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C14:G14',
                cellType: 'row',
            },
            {
                id: 8,
                text: "EBITDA",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C15:G15',
                cellType: 'row',
            },
            {
                id: 9,
                text: "Less: D&A",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C16:G16',
                cellType: 'row',
            },
            {
                id: 10,
                text: "EBIT",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C17:G17',
                cellType: 'row',
            },
            {
                id: 11,
                text: "Less: Interest Expense",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C18:G18',
                cellType: 'row',
            },
            {
                id: 12,
                text: "EBT",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C19:G19',
                cellType: 'row',
            },
            {
                id: 13,
                text: "Less: Taxes",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C20:G20',
                cellType: 'row',
            },
            {
                id: 14,
                text: "EBT (Tax Effected)",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C21:G21',
                cellType: 'row',
            },
            {
                id: 15,
                text: "Plus: D&A",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C22:G22',
                cellType: 'row',
            },
            {
                id: 16,
                text: "Less: CapEx",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C23:G23',
                cellType: 'row',
            },
            {
                id: 17,
                text: "Less: Increase in NWC",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C24:G24',
                cellType: 'row',
            },
            {
                id: 18,
                text: "Levered FCF",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C25:G25',
                cellType: 'row',
            },
            {
                id: 19,
                text: "Exit Enterprise Value",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C29',
                cellType: 'single',
            },
            {
                id: 20,
                text: "Debt (for IRR)",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C30',
                cellType: 'single',
            },
            {
                id: 21,
                text: "Exit Equity Value",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C31',
                cellType: 'single',
            },
            {
                id: 22,
                text: "Multiple of Money",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C32',
                cellType: 'single',
            },
            {
                id: 23,
                text: "IRR Estimate",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C33',
                cellType: 'single',
            },
            {
                id: 24,
                text: "Should Ninth Street Capital Partners go ahead with this acquisition?",
                answerFormat: 'string',
                inputType: 'user',
            },
        ],
        requiresPreviousQuestionCompleted: true,
        questionTitle: "FactoryCo LBO"
    },
];

export default LBO;

