import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';

interface Props {}

const ApplicantDashboard: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [assignedTests, setAssignedTests] = useState<any[]>([]);
  const [pastTests, setPastTests] = useState<any[]>([]);

  useEffect(() => {
    const fetchAssignedTests = async () => {
      if (user?.email) {
        const q = query(collection(db, 'interviews'), where('email', '==', user.email.toLowerCase().trim()));
        const querySnapshot = await getDocs(q);
        const tests: any = querySnapshot.docs.map((doc) => ({
          interviewId: doc.id,
          ...doc.data()
        }));

        const assignedTests: any[] = [];
        const pastTests: any[] = [];

        for (const test of tests) {
          if (['Completed', 'Expired', 'Graded'].includes(test.testStatus)) {
            pastTests.push(test);
          } else {
            if (
              test.interviewStartTime !== 0 &&
              test.interviewStartTime + test.timeLimitMinutes * 60 * 1000 < Date.now()
            ) {
              const interviewRef = doc(db, 'interviews', test.interviewId);
              await updateDoc(interviewRef, {
                testStatus: 'Completed'
              });
              test.testStatus = 'Completed';
              pastTests.push(test);
            } else {
              if (
                test.interviewStartTime !== 0 &&
                test.interviewStartTime + test.timeLimitMinutes * 60 * 1000 > Date.now()
              ) {
                // Interview is ongoing
                navigate(`/permissions/${test.interviewId}`);
              } else if (test.interviewStartTime === 0 && test.interviewDeadline < Date.now()) {
                // Interview has expired
                const interviewRef = doc(db, 'interviews', test.interviewId);
                await updateDoc(interviewRef, {
                  testStatus: 'Expired'
                });
                test.testStatus = 'Expired';
                pastTests.push(test);
              } else {
                // Interview is scheduled
                assignedTests.push(test);
              }
            }
          }
        }

        setAssignedTests(assignedTests);
        setPastTests(pastTests);
      }
    };
    fetchAssignedTests();
  }, [user, navigate]);

  return (
    <div>
    <NavBar />

    <div className="p-8 pt-24">

      <h2 className="text-3xl font-bold mb-6 text-gray-800">Welcome, {user?.name}</h2>
      <h3 className="text-xl font-semibold mb-4 text-gray-700">Your Assigned Tests:</h3>
      {assignedTests.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {assignedTests.map((test, index) => (
            <div
              key={index}
              className="p-4 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-lg border border-gray-200 bg-white"
            >
              <h4 className="text-lg font-semibold text-gray-800 mb-2">
                {test.roleName} at {test.companyName}
              </h4>
              <p className="text-gray-600 mb-4">{test.testDescription}</p>
              <button
                onClick={() => {
                  navigate(`/permissions/${test.interviewId}`);
                }}
                className="text-[#20B486] font-semibold hover:underline"
              >
                Start Test
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600">You have no assigned tests at the moment.</p>
      )}

      <h3 className="text-xl font-semibold mb-4 text-gray-700 mt-8">Your Past Tests:</h3>
      {pastTests.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {pastTests.map((test, index) => (
            <div
              key={index}
              className="p-4 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-lg border border-gray-200 bg-white"
            >
              <h4 className="text-lg font-semibold text-gray-800 mb-2">
                {test.roleName} at {test.companyName}
              </h4>
              <p className="text-gray-600 mb-4">{test.testDescription}</p>
              <span className="text-gray-500 font-semibold">
                {test.testStatus}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600">You have no past tests.</p>
      )}
    </div>
    </div>
  );
};

export default ApplicantDashboard;
