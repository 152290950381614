import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-modal';
import DataTable from 'react-data-table-component';
import { UserContext } from '../UserContext';
import { db } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import './AdminDashboard.scss';
import Interview from '../../types/Interview';
import FullInterviewInformation from '../../types/FullInterviewInformation';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import CandidateDetails from '../components/CandidateDetails';
import Button from '../components/Button';
import TechHubCaseStudy from '../tests/TechHubCaseStudy';
import ThreeStatements from '../tests/ThreeStatements';
import SpreadsheetSkills from '../tests/SpreadsheetSkills';
import LBO from '../tests/LBO';
import NavBar from '../components/NavBar';


interface Props {}

const DISABLE_EMAIL_SENDING = false;


const blankCandidate: Interview = {
  firstName: '',
  lastName: '',
  email: '',
  recruiterId: '',
  testStatus: 'Pending',
  groupName: '',
  roleName: '',
  companyName: '',
  assignedQuestions: [],
  timeLimitMinutes: 0,
  interviewStartTime: 0,
  interviewEndTime: 0,
  interviewDeadline: 0,
  screenLinks: [],
  webcamLinks: [],
  emailSuccessfullySent: false,
  dateAssigned: 0,
  answers: {},
  interviewInstructions: '',
  testId: '',
};

const testOptions = [
  { value: 'Tech Hub Case Study', label: 'Tech Hub Case Study' },
  { value: 'Three Statements', label: 'Three Statements' },
  { value: 'LBO', label: 'LBO' },
  { value: 'Spreadsheet Skills', label: 'Spreadsheet Skills' },
];

const testNameToTestMap = {
  'Tech Hub Case Study': TechHubCaseStudy,
  'Three Statements': ThreeStatements,
  'LBO': LBO,
  'Spreadsheet Skills': SpreadsheetSkills,
};

const testNameToInstructionsMap = {
  'Tech Hub Case Study': 'You will be asked to analyze a sales dataset, and answer a series of qualitative questions aimed towards diagnosing sales issues at TechHub.',
  'Three Statements': 'You will be tasked with filling out a basic 3 Statement Model for CerealCo in a Spreadsheet environment.',
  'LBO': 'You will be tasked with making a Paper Leveraged Buyout model for the acquisition of FactoryCo in a spreadsheet environment.',
  'Spreadsheet Skills': 'You will be tasked with completing a series of tasks in a spreadsheet environment.'
};

const AdminDashboard: React.FC<Props> = () => {
  const videoRef = useRef(null);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [candidates, setCandidates] = useState<FullInterviewInformation[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filteredCandidates, setFilteredCandidates] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [groupName, setGroupName] = useState('');
  const [roleName, setRoleName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [roleOptions, setRoleOptions] = useState<any[]>([]);
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [groupOptions, setGroupOptions] = useState<any[]>([]);
  const [modalStep, setModalStep] = useState(1);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [newCandidate, setNewCandidate] = useState<Interview>(blankCandidate);
  const [timeLimit, setTimeLimit] = useState(60);
  const [deadline, setDeadline] = useState('');
  const [invitationSentMessage, setInvitationSentMessage] = useState('');
  const [resendEmailSending, setResendEmailSending] = useState(false);
  const [selectedTest, setSelectedTest] = useState('');
  const [captionSrc, setCaptionSrc] = useState('');

  // New state variables for video modal
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({
    videoUrl: '',
    captionsUrl: '',
  })
  const [isVideoProcessing, setIsVideoProcessing] = useState(false);

  // New state variables for loading and completion
  const [isSendingInvitations, setIsSendingInvitations] = useState(false);
  const [invitationsSent, setInvitationsSent] = useState(false);

  const [selectedRows, setSelectedRows] = useState<FullInterviewInformation[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [interviewInstructions, setInterviewInstructions] = useState('');

  // State for column visibility
  const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>({
    dateAssigned: true,
    name: true,
    email: true,
    candidateId: false,
    roleName: false,
    companyName: false,
    testStatus: true,
    viewVideo: true,
    viewTest: true,
    score: true,
    performanceSummary: false,
    groupName: false,
    labels: true,
    deadline: false,
    successfullySentEmail: false,
    timeLimit: false,
    interviewInstructions: false,
  });

// Inside your component, define the column options and selectedColumns state
const columnOptions = [
  { value: 'name', label: 'Name' },
  { value: 'email', label: 'Email' },
  {  value: 'candidateId', label: 'Candidate ID' },
  { value: 'roleName', label: 'Role Name' },
  { value: 'companyName', label: 'Company Name' },
  { value: 'testStatus', label: 'Test Status' },
  { value: 'viewVideo', label: 'View Video' },
  { value: 'viewTest', label: 'View Test' },
  { value: 'score', label: 'Score' },
  { value: 'performanceSummary', label: 'Performance Summary' },
  { value: 'groupName', label: 'Group Name' },
  { value: 'interviewInstructions', label: 'Interview Instructions' },
  { value: 'deadline', label: 'Deadline' },
  { value: 'timeLimit', label: 'Time Limit'},
  { value: 'emailSuccessfullySent', label: 'Email Successfully Sent'},
  { value: 'dateAssigned', label: 'Date Assigned' },
  { value: 'labels', label: 'Labels' },
];

 // Initialize selectedColumns based on columnVisibility
 const [selectedColumns, setSelectedColumns] = useState(
  Object.keys(columnVisibility)
    .filter((key) => columnVisibility[key])
    .map((key) => columnOptions.find((option) => option.value === key))
);

// Handle column selection with a maximum of 8 columns
const handleColumnChange = (selectedOptions) => {
  setSelectedColumns(selectedOptions);
  const newVisibility = {};
  columnOptions.forEach((option) => {
    newVisibility[option.value] = selectedOptions.some(
      (selected) => selected.value === option.value
    );
  });
  setColumnVisibility(newVisibility);
};

const handleSelectedRowsChange = (state) => {
  setSelectedRows(state.selectedRows);
};


  // State for delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [performanceSummaryToDisplay, setPerformanceSummaryToDisplay] = useState('');

  const [labelOptions, setLabelOptions] = useState([]);

  useEffect(() => {
    let filtered = candidates;

    if (searchText) {
      filtered = filtered.filter(
        (candidate) =>
          `${candidate.firstName} ${candidate.lastName}`
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          candidate.email.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedGroup) {
      filtered = filtered.filter(
        (candidate) => candidate.groupName === selectedGroup
      );
    }

    if (selectedStatus) {
      filtered = filtered.filter(
        (candidate) => candidate.testStatus === selectedStatus
      );
    }

    setFilteredCandidates(filtered);
  }, [searchText, selectedGroup, selectedStatus, candidates]);

  // Fetch candidates from Firestore
  useEffect(() => {
    const fetchCandidates = async () => {
      const q = query(
        collection(db, 'interviews'),
        where('recruiterId', '==', user?.uid || '')
      );
      const querySnapshot = await getDocs(q);
      const candidatesData: any = querySnapshot.docs.map((doc) => ({
        ...doc.data() as Interview,
        id: doc.id,
        finalVideoUrl: '',
        captionsUrl: '',
        score: 0,
        performanceSummary: '',
        labels: [],
        pointBreakdown: {},
      }));

      // sort candidates by date assigned in descending order
      candidatesData.sort((a, b) => {
        if (!a.dateAssigned) return 1;
        if (!b.dateAssigned) return -1;
        return b.dateAssigned - a.dateAssigned;
      });

      // Fetch recruiter-specific data from 'interviewResults' collection
      const resultsQuery = query(
        collection(db, 'interviewResults'),
        where('recruiterId', '==', user?.uid || '')
      );
      const resultsSnapshot = await getDocs(resultsQuery);
      const resultsData = resultsSnapshot.docs.reduce((acc, doc) => {
        acc[doc.data().candidateId] = doc.data();
        return acc;
      }, {});

      // Merge recruiter-specific data into candidatesData
      candidatesData.forEach((candidate) => {
        const result = resultsData[candidate.id];
        if (result) {
          candidate.labels = result.labels || [];
          candidate.score = result.score || candidate.score;
          candidate.performanceSummary = result.performanceSummary || candidate.performanceSummary;
          candidate.finalVideoUrl = result.finalVideoUrl || candidate.finalVideoUrl;
          candidate.captionsUrl = result.captionsUrl || candidate.captionsUrl;
          candidate.pointBreakdown = result.details?.questionDetails || candidate.pointBreakdown;
        }
      });

      setCandidates(candidatesData);
      setFilteredCandidates(candidatesData);
      // unique role names
      const roles = new Set(
        candidatesData.map((candidate) => candidate.roleName)
      );
      setRoleOptions(
        Array.from(roles).map((role) => ({ value: role, label: role }))
      );
      // unique company names
      const companies = new Set(
        candidatesData.map((candidate) => candidate.companyName)
      );
      setCompanyOptions(
        Array.from(companies).map((company) => ({
          value: company,
          label: company,
        }))
      );
      // unique group names
      const groups = new Set(
        candidatesData.map((candidate) => candidate.groupName)
      );
      setGroupOptions(
        Array.from(groups).map((group) => ({ value: group, label: group }))
      );
     
    };
    if (user || invitationsSent) {
      fetchCandidates();
    }
  }, [user, invitationsSent]);  

  // Define columns for DataTable
  const columns = [
    {
      name: 'Date Assigned',
      cell: (row) => new Date(row.dateAssigned).toLocaleString([], { hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric' }),
      sortable: true,
      // treat missing dates as 0
      sortFunction: (rowA, rowB) => (rowA.dateAssigned || 0) - (rowB.dateAssigned || 0),
      omit: !columnVisibility.dateAssigned,
    },
    {
      name: 'Name',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      omit: !columnVisibility.name,
    },
    { name: 'Email', selector: (row) => row.email, sortable: true, omit: !columnVisibility.email },
    { name: 'Candidate ID', selector: (row) => row.id.slice(0, 8), sortable: true, omit: !columnVisibility.candidateId },
    { name: 'Role Name', selector: (row) => row.roleName, sortable: true, omit: !columnVisibility.roleName },
    { name: 'Company Name', selector: (row) => row.companyName, sortable: true, omit: !columnVisibility.companyName },
    { name: 'Test Status', selector: (row) => row.testStatus, sortable: true, omit: !columnVisibility.testStatus },
    { name: 'Email Successfully Sent', selector: (row) => row.emailSuccessfullySent ? 'Yes' : 'No', sortable: true, omit: !columnVisibility.emailSuccessfullySent },
    {
      name: 'View Video',
      cell: (row) =>
        row.finalVideoUrl ? (
          <Button
            onClick={() => handleViewVideo(row.finalVideoUrl, row.captionsUrl)}
            link
          >
            View Video
          </Button>
        ) : (
          'N/A'
        ),
      omit: !columnVisibility.viewVideo,
    },
    {
      name: 'View Test',
      cell: (row) =>
        row.assignedQuestions[0].spreadsheetLink ? (
          <a
            href={row.assignedQuestions[0].spreadsheetLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            View Test
          </a>
        ) : (
          'N/A'
        ),
      omit: !columnVisibility.viewTest,
    },
    {
      name: 'Score',
      selector: (row) => (row.score ? row.score : 'N/A'),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const scoreA = rowA.score !== null && rowA.score !== undefined ? rowA.score : 0; // Treat missing scores as 0
        const scoreB = rowB.score !== null && rowB.score !== undefined ? rowB.score : 0; // Treat missing scores as 0
        return scoreA - scoreB;
      },
      omit: !columnVisibility.score,
    },
    {
      name: 'Performance Summary',
      cell: (row) => 
        row.performanceSummary ? (
        <Button
          onClick={() => setPerformanceSummaryToDisplay(row.performanceSummary)}
          link
        >
          View Summary
        </Button>
      ) : (
        'N/A'
      ),
      sortable: false,
      wrap: true,
      omit: !columnVisibility.performanceSummary,
    },
    { name: 'Group Name', selector: (row) => row.groupName, sortable: true, omit: !columnVisibility.groupName },
    {
      name: 'Time Limit',
      cell: (row) => row.timeLimitMinutes !== undefined ? row.timeLimitMinutes : 0,
      sortable: true,
      sortFunction: (rowA, rowB) => (rowA.timeLimitMinutes !== undefined ? rowA.timeLimitMinutes : 0) - (rowB.timeLimitMinutes !== undefined ? rowB.timeLimitMinutes : 0),
      omit: !columnVisibility.timeLimit,
    },
    {
      name: 'Labels',
      cell: (row) => (
        <CreatableSelect
          isMulti
          options={labelOptions}
          value={row.labels ? row.labels.map((label) => ({ value: label, label })) : []}
          onChange={(selectedOptions) => handleLabelsChange(row, selectedOptions)}
        />
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const labelsA = rowA.labels ? rowA.labels.join('') : '';
        const labelsB = rowB.labels ? rowB.labels.join('') : '';
        return labelsA.localeCompare(labelsB);
      },
      omit: !columnVisibility.labels,
      minWidth: '300px',
    },
    {
      name: 'Deadline',
      cell: (row) => new Date(row.interviewDeadline).toLocaleString([], { hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric' }),
      sortable: true,
      omit: !columnVisibility.deadline,
    },
    {
      name: 'Interview Instructions',
      cell: (row) => row.interviewInstructions,
      sortable: false,
      wrap: true,
      omit: !columnVisibility.interviewInstructions,
    },
  ];

  const handleViewVideo = (videoLink, captionsLink) => {
    setIsVideoModalOpen(true);
    setIsVideoProcessing(true);
    setCurrentVideo({ videoUrl: videoLink, captionsUrl: captionsLink });
    setIsVideoProcessing(false);
  };

  useEffect(() => {
    let vttBlobUrl;

    if (!currentVideo.captionsUrl) {
      console.log('No captions URL');
      return;
    }

    const getCaptions = async () => {
      try {

        const proxyEndpoint = `/api/get-vtt?url=${encodeURIComponent(
          currentVideo.captionsUrl
        )}`;

        // Fetch the VTT file via the proxy
        const response = await axios.get(proxyEndpoint, {
          responseType: 'blob',
        });

        // Create a Blob URL
        const vttBlob = new Blob([response.data], { type: 'text/vtt' });
        vttBlobUrl = URL.createObjectURL(vttBlob);


        // Set the caption source in state
        setCaptionSrc(vttBlobUrl);
      } catch (error) {
        console.error('Error fetching VTT:', error);
      }
    };

    getCaptions();

    return () => {
      // Cleanup
      if (vttBlobUrl) {
        URL.revokeObjectURL(vttBlobUrl);
      }
      // Clear the caption source
      setCaptionSrc('');
    };
  }, [currentVideo]);

  // Function to handle updating labels
  const handleLabelsChange = async (candidate, selectedOptions) => {
    const labels = selectedOptions ? selectedOptions.map((option) => option.value) : [];
  
    // Update the labels in Firestore (in the recruiter-specific collection)
    try {
      const docRef = doc(db, 'interviewResults', `${candidate.id}`);
      await setDoc(docRef, { recruiterId: user.uid, candidateId: candidate.id, labels }, { merge: true });

      // Update the local state
      setCandidates((prevCandidates) =>
        prevCandidates.map((c) =>
          c.id === candidate.id ? { ...c, labels } : c
        )
      );

      // Update labelOptions
      setLabelOptions((prevOptions) => {
        const newOptions = labels.map((label) => ({ value: label, label }));
        const mergedOptions = [...prevOptions, ...newOptions];
        const uniqueOptions = Array.from(new Set(mergedOptions.map((option) => option.value))).map((value) => {
          return mergedOptions.find((option) => option.value === value);
        });
        console.log('Unique options:', uniqueOptions);
        return uniqueOptions;
      });
      
    } catch (error) {
      console.error('Error updating labels:', error);
    }
  };

  const handleDeleteSelectedCandidates = () => {
    if (selectedRows.length === 0) {
      alert('No candidates selected.');
      return;
    }
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteSelectedCandidates = async () => {
    try {
      const deletePromises = selectedRows.map(async (candidate) => {
        // Delete candidate from Firebase
        await deleteDoc(doc(db, 'interviews', candidate.id));
        // Delete recruiter-specific data
        try {
          await deleteDoc(doc(db, 'interviewResults', `${candidate.id}`));
        } catch (error) {
          console.warn(`Failed to delete interviewResults for candidate ${candidate.id}:`, error);
        }
      });

      await Promise.all(deletePromises);

      // Update state
      setCandidates((prev) =>
        prev.filter((candidate) => !selectedRows.some((row) => row.id === candidate.id))
      );
      setFilteredCandidates((prev) =>
        prev.filter((candidate) => !selectedRows.some((row) => row.id === candidate.id))
      );

      setIsDeleteModalOpen(false);
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    } catch (error) {
      console.error('Error deleting candidates:', error);
      alert('Failed to delete selected candidates.');
    }
  };

  const handleResendEmailToSelectedCandidates = () => {
    if (selectedRows.length === 0) {
      alert('No candidates selected.');
      return;
    }
    setIsResendModalOpen(true);
  };

  const confirmResendEmailToSelectedCandidates = async () => {
    setResendEmailSending(true);
    try {
      const response = await axios.post('/api/send-invites', {
        recipients: selectedRows,
        roleName: selectedRows[0].roleName,
        companyName: selectedRows[0].companyName,
        timeLimit: selectedRows[0].timeLimitMinutes,
        deadline: selectedRows[0].interviewDeadline,
        interviewInstructions: selectedRows[0].interviewInstructions,
      });

      const emailStatus = response.data.emailStatus;

      // Update each interview with email status
      const updatePromises = emailStatus.map(async ({ email, status }) => {
        const candidate = candidates.find((c) => c.email === email);
        if (candidate) {
          const candidateRef = doc(db, 'interviews', candidate.id);
          await updateDoc(candidateRef, {
            emailSuccessfullySent: status === 'successful',
          });

          // Update the local state
          setCandidates((prevCandidates) =>
            prevCandidates.map((c) =>
              c.id === candidate.id ? { ...c, emailSuccessfullySent: status === 'successful' } : c
            )
          );
        }
      });

      await Promise.all(updatePromises);

      setIsResendModalOpen(false);
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
      alert('Emails resent successfully.');
    } catch (error) {
      console.error('Error resending emails:', error);
      alert('Failed to resend emails to selected candidates.');
    }
    setResendEmailSending(false);
  };

  // Open and close modal
  const openModal = () => {
    setModalIsOpen(true);
    setInvitationsSent(false);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setParsedData([]);
    setGroupName('');
    setSelectedTest('');
    setRoleName('');
    setCompanyName('');
    setModalStep(1);
    setErrors({});
    setNewCandidate(blankCandidate);
    setIsSendingInvitations(false);
    setInvitationsSent(false);
    setDeadline('');
    setInvitationSentMessage('');
  };

  // Handle CSV upload
  const handleCsvUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target?.result;
      if (typeof text === 'string') {
        parseCsv(text);
      }
    };
    reader.readAsText(file);
  };

  // Trigger file input click
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Parse CSV data
  const parseCsv = (text: string) => {
    const lines = text.split('\n').filter((line) => line.trim() !== '');
    // slice(1) to skip header row
    const data = lines.slice(1).map((line) => {
      const [firstName, lastName, email] = line
        .split(',')
        .map((item) => item.trim());
      // capitalize first letter of first and last name
      const capitalize = (str: string) =>
        str.replace(/\b\w/g, (char) => char.toUpperCase());
      return {
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        email: email.toLowerCase(),
      };
    });
    setParsedData(data);
  };

  const createCopyOfSheet = async (link: string, candidateName: string) => {
    try {
      // call copy-sheet function in backend
      const templateId = link.split('/')[5];
      const response = await axios.post('/api/copy-sheet', {
        candidateName,
        templateId,
      });
      return response.data.sheetLink;
    } catch (error) {
      console.error('Error creating copy of sheet:', error);
      throw new Error('Failed to create copy of sheet.');
    }
  };

  // Handle Next Step
  const handleNextStep = () => {
    const newErrors: { [key: string]: string } = {};

    // Validate groupName
    if (!groupName) {
      newErrors.groupName = 'Please select or enter a group name.';
    }

    // Validate parsedData
    if (parsedData.length === 0) {
      newErrors.parsedData = 'Please add at least one candidate.';
    } else {
      // Check if all parsedData entries have firstName, lastName, and email
      parsedData.forEach((candidate) => {
        if (!candidate.firstName || !candidate.lastName || !candidate.email) {
          newErrors.parsedData = 'Please fill out all candidate fields.';
        }
      });
    }

    // Validate each email in parsedData using a more complex regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    parsedData.forEach((candidate) => {
      if (!emailPattern.test(candidate.email)) {
      newErrors.parsedData = `The email ${candidate.email} is invalid.`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setModalStep(2);
    }
  };

  // Handle editing parsed data
  const handleEditParsedData = (index: number, field: string, value: string) => {
    const updatedData = [...parsedData];
    updatedData[index][field] = value;
    setParsedData(updatedData);
  };

  // Handle adding new candidate
  const handleAddNewCandidate = () => {
    if (
      newCandidate.firstName &&
      newCandidate.lastName &&
      newCandidate.email
    ) {
      setParsedData([...parsedData, newCandidate]);
      setNewCandidate(blankCandidate);
    } else {
      alert('Please fill out all fields before adding.');
    }
  };

  // Handle deleting candidate from parsed data
  const handleDeleteParsedCandidate = (index: number) => {
    const updatedData = [...parsedData];
    updatedData.splice(index, 1);
    setParsedData(updatedData);
  };

  // Handle sending emails and saving candidates
  const handleSendInvitations = async () => {
    const newErrors: { [key: string]: string } = {};

    if (!roleName) {
      newErrors.roleName = 'Please select or enter a role name.';
    }

    if (!companyName) {
      newErrors.companyName = 'Please select or enter a company name.';
    }

    if (!timeLimit) {
      newErrors.timeLimit = 'Please enter a time limit.';
    }

    if (!deadline) {
      newErrors.deadline = 'Please select a deadline.';
    }

    if (!selectedTest) {
      newErrors.selectedTest = 'Please select a test.';
    }

    const duplicateEmails = parsedData.filter((candidate) => {
      return candidates.some(
        (c) =>
          c.email === candidate.email &&
          c.groupName === groupName &&
          c.roleName === roleName &&
          c.companyName === companyName
      );
    });

    if (duplicateEmails.length > 0) {
      alert(
        `The following emails are already in the list for the group "${groupName}", role "${roleName}", and company "${companyName}": ${duplicateEmails
          .map((candidate) => candidate.email)
          .join(', ')}. They have automatically been removed from this list. If you would like to add them, please delete them from the existing list and re-upload the CSV.`
      );
      setParsedData(parsedData.filter((candidate) => !duplicateEmails.includes(candidate)));
      return;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSendingInvitations(true);

    const test = testNameToTestMap[selectedTest];

    try {
      // Save candidates
      const saveCandidatesPromises = parsedData.map(async (data) => {
        try {

          // TODO: Create a copy of the Google Sheet for each candidate
          // const sheetCopyLink = await createCopyOfSheet(
          //   googleSheetLink,
          //   `${data.firstName} ${data.lastName}`
          // );

          const testCopy = JSON.parse(JSON.stringify(test));

          const testWithCopiedSheets = await Promise.all(testCopy.map(async (question) => {
          if (question.spreadsheetLink) {
            const sheetCopyLink = await createCopyOfSheet(
            question.spreadsheetLink,
            `${data.firstName} ${data.lastName}`
            );
            question.spreadsheetLink = sheetCopyLink;
          }
          return question;
          }));

    
          const interview: Interview = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            recruiterId: user?.uid || '',
            testStatus: 'Pending',
            groupName,
            roleName,
            companyName,
            interviewStartTime: 0,
            interviewEndTime: 0,
            interviewDeadline: new Date(`${deadline}:00`).getTime(),
            screenLinks: [],
            webcamLinks: [],
            assignedQuestions: testWithCopiedSheets,
            timeLimitMinutes: timeLimit,
            emailSuccessfullySent: false,
            dateAssigned: Date.now(),
            answers: {},
            interviewInstructions,
            testId: selectedTest,
          };
    
          const interviewRef = await addDoc(collection(db, 'interviews'), interview);
          return { success: true, data, interviewRef };
        } catch (err) {
          console.error('Error saving candidate to Firebase:', err);
          return { success: false, data };
        }
      });
    
      const saveResults = await Promise.all(saveCandidatesPromises);
    
      // Filter candidates that were successfully saved to Firebase
      const successfullySavedCandidates = saveResults.filter((res) => res.success).map((res) => res.data);
    
      // Send emails
      let emailStatus = [];
      if (successfullySavedCandidates.length > 0 && !DISABLE_EMAIL_SENDING) {
        const response = await axios.post('/api/send-invites', {
          recipients: successfullySavedCandidates,
          roleName,
          companyName,
          timeLimit,
          deadline: new Date(deadline).getTime(),
          interviewInstructions,
        });
    
        emailStatus = response.data.emailStatus;
    
        // Update each interview with email status
        const updateInterviewPromises = emailStatus.map(async ({ email, status }) => {
          const candidateResult = saveResults.find((res) => res.data.email === email);
          if (candidateResult && candidateResult.interviewRef) {
            await updateDoc(candidateResult.interviewRef, {
              emailSuccessfullySent: status === 'successful',
            });
          }
        });
    
        await Promise.all(updateInterviewPromises);
      }
    
      setInvitationsSent(true);
      
      let message = '';
      let problem = false;
      // if not all candidates were saved, update send invitations message
      if (successfullySavedCandidates.length !== parsedData.length) {
        message = `We successfully created tests for ${successfullySavedCandidates.length} of the ${parsedData.length} candidates. You can retry by clicking the "Add Candidates" button again, and we'll automatically skip the ones we've already saved. `;
        problem = true;
      } 
      const numEmailsSent = emailStatus.filter((status) => status.status === 'successful').length;

      if (successfullySavedCandidates.length > 0){
        if (numEmailsSent === successfullySavedCandidates.length) {
          message += 'We successfully sent emails to all those candidates we saved.';
        } else{
          message += `Of the ${successfullySavedCandidates.length} created tests, we successfully sent emails to ${numEmailsSent} of them. You can resend emails manually by selecting the candidates and clicking the "Resend Email to Selected" button.`;
          problem = true;
        }
      }
      if (problem) {
        message = `We ran into some problems. ${message}`;
        setInvitationSentMessage(message);
      } else {
        setInvitationSentMessage('Successfully sent emails and saved candidates.');
      }

    } catch (error) {
      console.error('Error adding candidates:', error);
      alert('Failed to add candidates.');
    } finally {
      setIsSendingInvitations(false);
    }
    
  };

  const downloadCandidatesAsCSV = () => {
    // Define CSV headers
    const headers = [
      'First Name',
      'Last Name',
      'Email',
      'Role Name',
      'Company Name',
      'Test Status',
      'Score',
      'Performance Summary',
      'Group Name',
      'Deadline',
      'Labels',
    ];

    // Map candidates data to CSV rows
    const rows = candidates.map((candidate) => [
      candidate.firstName,
      candidate.lastName,
      candidate.email,
      candidate.roleName,
      candidate.companyName,
      candidate.testStatus,
      candidate.score,
      candidate.performanceSummary,
      candidate.groupName,
      new Date(candidate.interviewDeadline).toLocaleString(),
      candidate.labels ? candidate.labels.join('; ') : '',
    ]);

    // Combine headers and rows
    const csvContent =
      [headers.join(','), ...rows.map((row) => row.map((value) => `"${value}"`).join(','))].join('\n');

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the blob
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'candidates.csv';
    link.click();
  };

  return (
    <div>
      <NavBar />
    <div className="pt-24 p-8">
      {/* Search and Filter */}
      <div className="flex justify-between items-center mb-4">
        <div className="relative w-1/3">
          <input
        type="text"
        placeholder="Search candidates by name or email..."
        className="border p-2 rounded w-full"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
          />
          {searchText && (
        <button
          className="absolute top-2 right-2 text-gray-500 text-2xl"
          onClick={() => setSearchText('')}
        >
          &times;
        </button>
          )}
          { !searchText &&
          <svg
        className="absolute top-2 right-2 w-5 h-5 text-gray-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
          >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21 21l-4.35-4.35m1.85-5.15a7 7 0 11-14 0 7 7 0 0114 0z"
        ></path>
          </svg>
      }
        </div>
        <div>
          <Button
        onClick={downloadCandidatesAsCSV}
        className="mr-2"
        inverted
        bold
          >
        Download Candidates CSV
          </Button>
          <Button
        onClick={openModal}
        bold
          >
        Add Candidates
          </Button>
        </div>
      </div>

      <div className="flex space-x-4 mb-4">
        <select
          className="border p-2 rounded"
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
        >
          <option value="">All Groups</option>
          {groupOptions.map((group, index) => (
            <option key={index} value={group.value}>
              {group.label}
            </option>
          ))}
        </select>

        <select
          className="border p-2 rounded"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="">All Statuses</option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Completed">Completed</option>
          <option value="Graded">Graded</option>
          {/* Add more statuses as needed */}
        </select>
      </div>

      {/* Column Toggle */}
      

    <div className="mb-4">
      <h3 className="font-semibold mb-2">Select Columns to Display:</h3>
      <Select
        isMulti
        options={columnOptions}
        value={selectedColumns}
        onChange={handleColumnChange}
        placeholder="Select columns..."
        closeMenuOnSelect={false}
      />
    </div>


      {/* Actions for Selected Rows */}
      {selectedRows.length > 0 && (
        <div className="mb-4">
          <h3 className="font-semibold">Actions for Selected Candidates:</h3>
          <div className="flex space-x-4">
            <Button
              onClick={handleDeleteSelectedCandidates}
              color="red"
              bold
              className='mt-2'
            >
              Delete Selected
            </Button>
            <Button
              onClick={handleResendEmailToSelectedCandidates}
              className='mt-2'
              bold
            >
              Resend Email to Selected
            </Button>
          </div>
        </div>
      )}

      {/* Candidates Table */}
      <DataTable
        columns={columns}
        data={filteredCandidates}
        selectableRows
        onSelectedRowsChange={handleSelectedRowsChange} 
        pagination
        highlightOnHover
        responsive
        expandableRows
        expandableRowsComponent={CandidateDetails}
      />



        {/* Video Modal */}
      <Modal
        isOpen={isVideoModalOpen}
        onRequestClose={() => setIsVideoModalOpen(false)}
        contentLabel="View Video"
        ariaHideApp={false}
        style={{
          content: {
            top: '10%',
            left: '20%',
            right: '20%',
            bottom: '10%',
            padding: '2%',
            border: 'none',
            background: 'white',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <div className="relative w-full">
          <h2 className="text-xl font-bold mb-4">Candidate Video</h2>

          {isVideoProcessing ? (
            <div className="flex items-center justify-center w-full h-full">
              <p>Processing video...</p>
            </div>
          ) : currentVideo.videoUrl ? (
            <video
              ref={videoRef}
              controls
              src={currentVideo.videoUrl}
              className="w-full h-full"
              style={{ objectFit: 'contain' }}
            >
              <track
                src={captionSrc}
                kind="subtitles"
                srcLang="en"
                label="English"
              />
            </video>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p>Loading video...</p>
            </div>
          )}
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setIsVideoModalOpen(false)}
              color='gray'
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Selected"
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
          <p>
            Are you sure you want to delete {selectedRows.length} selected candidate(s)?
          </p>
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setIsDeleteModalOpen(false)}
              className="mr-2"
              color='gray'
            >
              Cancel
            </Button>
            <Button
              onClick={confirmDeleteSelectedCandidates}
              color="red"
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>

            {/* Resend Email Confirmation Modal for Selected Candidates */}
            <Modal
        isOpen={isResendModalOpen}
        onRequestClose={() => setIsResendModalOpen(false)}
        contentLabel="Confirm Resend Email to Selected"
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Confirm Resend Emails</h2>
          <p>
            Are you sure you want to resend the assessment invitation emails to{' '}
            {selectedRows.length} selected candidate(s)?
          </p>
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setIsResendModalOpen(false)}
              className="mr-2"
              color='gray'
            >
              Cancel
            </Button>
            <Button
              disabled={resendEmailSending}
              onClick={confirmResendEmailToSelectedCandidates}
            >
              Resend Emails
            </Button>
          </div>
        </div>
      </Modal>


      {/* Performance Summary Modal */}
      <Modal
        isOpen={performanceSummaryToDisplay !== ''}
        onRequestClose={() => setPerformanceSummaryToDisplay('')}
        contentLabel="Performance Summary"
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Performance Summary</h2>
          <p>{performanceSummaryToDisplay}</p>
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => setPerformanceSummaryToDisplay('')}
              color='gray'
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>

      {/* Add Candidates Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Candidates"
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
      >
        {/* Close button */}
        <div className="relative mb-4">
          <h2 className="text-xl font-bold">Add Candidates</h2>
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 text-gray-500 hover:text-gray-700 focus:outline-none text-4xl font-bold"
          >
            &times;
          </button>
        </div>

        {isSendingInvitations ? (
          // Loading State
          <div className="flex flex-col items-center justify-center">
            <p className="text-lg mb-4">Sending invitations...</p>
            <div className="loader mb-4"></div>
            <Button
              onClick={closeModal}
              color='gray'
            >
              Close
            </Button>
          </div>
        ) : invitationsSent ? (
          // Completion State
          <div className="flex flex-col items-center justify-center">
            <p className="text-lg mb-4">{invitationSentMessage}</p>
            <Button
              onClick={closeModal}
            >
              Close
            </Button>
          </div>
        ) : (
          // Existing Modal Steps (modalStep 1 and 2)
          <>
            {modalStep === 1 && (
              <>
                {/* Group Name Selection */}
                <div className="mb-4">
                  <label className="block font-semibold mb-2">Group Name</label>
                  <CreatableSelect
                    options={groupOptions}
                    onChange={(option) => setGroupName(option?.value || '')}
                    value={
                      groupName ? { label: groupName, value: groupName } : null
                    }
                    placeholder="Type or select a group"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: '40px',
                      }),
                    }}
                  />
                  {errors.groupName && (
                    <p className="text-red-500">{errors.groupName}</p>
                  )}
                </div>

                {/* CSV Upload */}
                <div className="mb-4">
                  <label className="block font-semibold mb-2">
                    Upload CSV File (First name, Last name, Email)
                  </label>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleCsvUpload}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                  <Button
                    onClick={handleUploadClick}
                  >
                    Choose CSV File
                  </Button>
                  <p className="text-sm text-gray-500 mt-2">
                    Or edit the preview to add candidates manually.
                  </p>
                  {/* Preview of Parsed Data */}
                  <div className="mb-4">
                    <h3 className="font-semibold mb-2">Preview:</h3>
                    <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                      <table className="min-w-full border">
                        <thead>
                          <tr>
                            <th className="border px-2 py-1">First Name</th>
                            <th className="border px-2 py-1">Last Name</th>
                            <th className="border px-2 py-1">Email</th>
                            <th className="border px-2 py-1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {parsedData.map((row, index) => (
                            <tr key={index}>
                              <td className="border px-2 py-1">
                                <input
                                  type="text"
                                  value={row.firstName}
                                  onChange={(e) =>
                                    handleEditParsedData(
                                      index,
                                      'firstName',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                              <td className="border px-2 py-1">
                                <input
                                  type="text"
                                  value={row.lastName}
                                  onChange={(e) =>
                                    handleEditParsedData(
                                      index,
                                      'lastName',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                              <td className="border px-2 py-1">
                                <input
                                  type="email"
                                  value={row.email}
                                  onChange={(e) =>
                                    handleEditParsedData(
                                      index,
                                      'email',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                              <td className="border px-2 py-1">
                                <button
                                  onClick={() => handleDeleteParsedCandidate(index)}
                                  className='text-red-500 underline'
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                          {/* Extra row for adding new candidate */}
                          <tr>
                            <td className="border px-2 py-1">
                              <input
                                type="text"
                                value={newCandidate.firstName || ''}
                                onChange={(e) =>
                                  setNewCandidate({
                                    ...newCandidate,
                                    firstName: e.target.value,
                                  })
                                }
                                className="w-full"
                                placeholder="First Name"
                              />
                            </td>
                            <td className="border px-2 py-1">
                              <input
                                type="text"
                                value={newCandidate.lastName || ''}
                                onChange={(e) =>
                                  setNewCandidate({
                                    ...newCandidate,
                                    lastName: e.target.value,
                                  })
                                }
                                className="w-full"
                                placeholder="Last Name"
                              />
                            </td>
                            <td className="border px-2 py-1">
                              <input
                                type="email"
                                value={newCandidate.email || ''}
                                onChange={(e) =>
                                  setNewCandidate({
                                    ...newCandidate,
                                    email: e.target.value,
                                  })
                                }
                                className="w-full"
                                placeholder="Email"
                              />
                            </td>
                            <td className="border px-2 py-1">
                              <Button
                                onClick={handleAddNewCandidate}
                                link
                              >
                                Add
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {errors.parsedData && (
                    <p className="text-red-500">{errors.parsedData}</p>
                  )}
                </div>

                {/* Next Button */}
                <div className="flex justify-end">
                  <Button
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}

            {modalStep === 2 && (
              <>
              
                <div className="mb-4">
                  <label className="block font-semibold mb-2">Select a Test</label>
                    <Select
                    options={testOptions}
                    onChange={(option) => {
                      setSelectedTest(option?.value || '');
                      setInterviewInstructions(testNameToInstructionsMap[option?.value || ''] || interviewInstructions);
                    }}
                    value={
                      selectedTest ? { label: selectedTest, value: selectedTest } : null
                    }
                    placeholder="Select a test"
                    isClearable
                    styles={{
                      control: (provided) => ({
                      ...provided,
                      minHeight: '40px',
                      }),
                    }}
                    />
                  {errors.selectedTest && (
                    <p className="text-red-500">{errors.selectedTest}</p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block font-semibold mb-2">Interview Instructions</label>
                  <p>This will be included in the email sent to candidates.</p>
                  <textarea
                    className="w-full p-2 border rounded"
                    value={interviewInstructions}
                    onChange={(e) => setInterviewInstructions(e.target.value)}
                    rows={4}
                    placeholder="Enter any specific instructions for the interview"
                  />
                </div>

                <div className="mb-4">
                  <label className="block font-semibold mb-2">
                    Time limit (min)
                  </label>
                  <input
                    type="number"
                    className="w-full p-2 border rounded"
                    value={timeLimit}
                    onChange={(e) => setTimeLimit(parseInt(e.target.value))}
                  />
                  {errors.timeLimit && (
                    <p className="text-red-500">{errors.timeLimit}</p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block font-semibold mb-2">Deadline (Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone})</label>
                  <input
                    type="datetime-local"
                    className="w-full p-2 border rounded"
                    value={deadline}
                    onChange={(e) => setDeadline(e.target.value)}
                  />
                  {errors.deadline && (
                    <p className="text-red-500">{errors.deadline}</p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block font-semibold mb-2">Role Name</label>
                  <CreatableSelect
                    options={roleOptions}
                    onChange={(option) => setRoleName(option?.value || '')}
                    value={
                      roleName ? { label: roleName, value: roleName } : null
                    }
                    placeholder="Type or select a role"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: '40px',
                      }),
                    }}
                  />
                  {errors.roleName && (
                    <p className="text-red-500">{errors.roleName}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block font-semibold mb-2">
                    Company Name
                  </label>
                  <CreatableSelect
                    options={companyOptions}
                    onChange={(option) => setCompanyName(option?.value || '')}
                    value={
                      companyName
                        ? { label: companyName, value: companyName }
                        : null
                    }
                    placeholder="Type or select a company"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: '40px',
                      }),
                    }}
                  />
                  {errors.companyName && (
                    <p className="text-red-500">{errors.companyName}</p>
                  )}
                </div>

                {/* Back and Submit Buttons */}
                <div className="flex justify-between">
                  <Button
                    onClick={() => setModalStep(1)}
                    color='gray'
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleSendInvitations}
                  >
                    Add Candidates
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
    </div>
  );
};

export default AdminDashboard;
