// Signup.tsx
import React, { useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { UserContext } from '../UserContext';
import { auth, db } from '../firebase';
import Button from '../components/Button';
import NavBar from '../components/NavBar';

interface Props {}

const Signup: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('job_candidate'); // Default user type
  const [name, setName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    if (userType === 'recruiter' && accessCode !== 'AXJDSDU') {
      alert('Invalid access code!');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const { user } = userCredential;
      // Store user type in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email,
        name,
        userType,
      });
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error signing up:', error);
      alert(error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    if (!userType) {
      alert('Please select your user type before signing up.');
      return;
    }
    if (userType === 'recruiter' && accessCode !== 'AXJDSDU') {
      alert('Invalid access code!');
      return;
    }
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const { user } = userCredential;
      // Store user type and name in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        name: user.displayName || '',
        userType,
      });
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error signing up with Google:', error);
      alert(error.message);
    }
  };

  return (
    <div>
      <NavBar />
    <div className="flex items-center justify-center h-screen bg-blue-50 pt-16">
      <form
        className="bg-white p-10 rounded-md shadow-lg max-w-lg w-full border border-gray-200"
      >
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          Create your account
        </h2>
        {/* Name Input */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold">Name</label>
          <input
        type="text"
        className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="Enter your full name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
          />
        </div>
        {/* Email Input */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold">Email</label>
          <input
        type="email"
        className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
          />
        </div>
        {/* Password Input */}
        <label className="block text-gray-700 font-bold">Password</label>

        <div className="mb-4 relative">
          <input
        type={showPassword ? 'text' : 'password'}
        className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
          />
          <div
        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
        onClick={() => setShowPassword(!showPassword)}
          >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>
        {/* Confirm Password Input */}
        <label className="block text-gray-700 font-bold">
          Confirm Password
        </label>
        <div className="mb-6 relative">
          <input
        type={showConfirmPassword ? 'text' : 'password'}
        className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="Confirm your password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
          />
          <div
        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>
        {/* User Type Selection */}
        <div className="mb-6">
          <label className="block text-gray-700 font-bold mb-2">I am a:</label>
          <div className="flex items-center">
        <input
          type="radio"
          id="job_candidate"
          name="userType"
          value="job_candidate"
          checked={userType === 'job_candidate'}
          onChange={(e) => {
            setUserType(e.target.value);
            setAccessCode('');
          }}
          className="mr-2"
        />
        <label htmlFor="job_candidate" className="mr-4">
          Job Candidate
        </label>
        <input
          type="radio"
          id="recruiter"
          name="userType"
          value="recruiter"
          checked={userType === 'recruiter'}
          onChange={(e) => {
            setUserType(e.target.value);
            setShowAccessCodeModal(true);
          }}
          className="mr-2"
        />
        <label htmlFor="recruiter">Recruiter</label>
          </div>
        </div>
        {/* Sign Up Button */}
        <Button
          onClick={handleSubmit}
          className="w-full mb-3"
          bold
        >
          Sign Up
        </Button>
        {/* Google Sign Up Button */}
        <Button
          onClick={handleGoogleSignUp}
          className="w-full"
          bold
          inverted
        >
          Sign Up with Google
        </Button>
        {/* Navigate to Login */}
        <p
          className="text-sm font-bold text-gray-600 mt-4 text-center cursor-pointer"
          onClick={() => navigate('/login')}
        >
          Already have an account? Log in instead.
        </p>
      </form>

      {/* Access Code Modal */}
      {showAccessCodeModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <h2 className="text-xl font-bold mb-4">Enter Access Code</h2>
            <input
              type="text"
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
              placeholder="Access Code"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
            />
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() => {
                  setShowAccessCodeModal(false);

                  if (accessCode === 'AXJDSDU') {
                    setUserType('recruiter');
                  } else{
                    alert('Invalid access code. Contact us if you would like to become a recruiter.');
                    setUserType('job_candidate');
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default Signup;
