import React, { useState, useEffect, useRef } from 'react';
import Button from './Button';
import QuestionPart from '../../types/QuestionPart';
import { CheckCircleIcon, ExclamationCircleIcon, DownloadIcon } from '@heroicons/react/solid';

interface SpreadsheetQuestionProps {
  questionParts: QuestionPart[];
  sheetLink: string;
  onBack: () => void;
  onSaveAnswers: (answers: { [key: string]: { value: any; formula: any } }) => void;
  initialAnswers: { [key: string]: any };
  questionInstruction: string;
  questionAssumptions: string;
}

const SpreadsheetQuestion: React.FC<SpreadsheetQuestionProps> = ({
  questionParts,
  sheetLink,
  onBack,
  onSaveAnswers,
  initialAnswers,
  questionInstruction,
  questionAssumptions,
}) => {
  // Updated to store both value and formula
  const [answers, setAnswers] = useState<{ [key: string]: { value: any; formula: any } }>(() => {
    const initialFormattedAnswers: { [key: string]: { value: any; formula: any } } = {};
    for (const key in initialAnswers) {
      initialFormattedAnswers[key] = { value: initialAnswers[key], formula: null };
    }
    return initialFormattedAnswers;
  });

  const [isSaved, setIsSaved] = useState<boolean>(true); // Tracks save status
  const [lastSaveTime, setLastSaveTime] = useState<Date | null>(null); // Stores last save timestamp
  const [isExtracting, setIsExtracting] = useState<boolean>(false); // Tracks extraction status
  const [showFormulas, setShowFormulas] = useState<boolean>(false); // Toggle for showing formulas
  const lastSavedAnswersRef = useRef<{ [key: string]: { value: any; formula: any } }>({});

  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference to the debounce timeout

  // Extract Spreadsheet ID from the link
  const getSpreadsheetIdFromLink = (link: string): string | null => {
    const regex = /\/d\/([a-zA-Z0-9-_]+)/;
    const match = link.match(regex);
    return match ? match[1] : null;
  };

  useEffect(() => {
    // Fetch values for spreadsheet-based questions on initial load
    const spreadsheetCells = questionParts
      .filter((part) => part.inputType === 'spreadsheet' && part.cell)
      .map((part) => {
        return {
          id: part.id,
          cell: part.cell,
          cellType: part.cellType,
        };
      });

    if (spreadsheetCells.length > 0) {
      fetchCellValues(spreadsheetCells).then((cellValues) => {
        questionParts.forEach((part) => {
          if (part.inputType === 'spreadsheet' && part.cell) {
            setAnswers((prevAnswers) => ({
              ...prevAnswers,
              [part.id]: cellValues[part.id] || { value: '', formula: '' },
            }));
          }
        });
      });
    }
  }, [questionParts, sheetLink]);

  // Debounce Auto-save: Save after 5 seconds of no typing
  useEffect(() => {
    if (!isSaved) {
      // Clear any existing timeout to reset the debounce
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }

      // Set a new timeout to save after 5 seconds
      saveTimeoutRef.current = setTimeout(() => {
        handleSave();
      }, 5000); // 5000 ms = 5 seconds
    }

    // Cleanup function to clear timeout if component unmounts or answers change
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [answers, isSaved]); // Dependencies include answers and isSaved

  const fetchCellValues = async (
    cells: { cell: string; cellType: string; id: number }[]
  ): Promise<{ [cellId: string]: { value: any; formula: any } }> => {
    const spreadsheetId = getSpreadsheetIdFromLink(sheetLink);

    if (!spreadsheetId) {
      console.error('Invalid sheet link');
      return {};
    }

    try {
      const response = await fetch('/api/fetch-cell-value', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ spreadsheetId, cells }),
      });

      const data = await response.json();

      if (data.success) {
        const cellValues: { [cellId: string]: { value: any; formula: any } } = {};
        data.cellValues.forEach((item: { cell: string; value: any; formula: any; id: number }) => {
          cellValues[item.id] = {
            value: item.value,
            formula: item.formula,
          };
        });
        return cellValues;
      } else {
        console.error('Error fetching cell values:', data.error);
        return {};
      }
    } catch (error) {
      console.error('Error fetching cell values:', error);
      return {};
    }
  };

  const handleInputChange = (id: number, value: string | number) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: {
        ...prevAnswers[id],
        value: value,
      },
    }));
    setIsSaved(false); // Mark as unsaved when inputs change
  };

  // Handle "Back to Questions" click with immediate save
  const handleBack = () => {
    const currentAnswersString = JSON.stringify(answers);
    const lastSavedAnswersString = JSON.stringify(lastSavedAnswersRef.current);


    if (currentAnswersString !== lastSavedAnswersString) {
      handleSave();
    }

    onBack();
  };

  // Save function to handle both manual and auto-save
  const handleSave = () => {
    onSaveAnswers(answers); // Pass the full answers object
    lastSavedAnswersRef.current = { ...answers };
    setIsSaved(true);
    setLastSaveTime(new Date());
  
    // Clear any existing timeout since we've just saved
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
      saveTimeoutRef.current = null;
    }
  };
  

  // Handle Extract Cell Values button click
  const handleExtractCellValues = async () => {
    setIsExtracting(true);
    const spreadsheetCells = [];
    questionParts.forEach((part) => {
      if (part.inputType === 'spreadsheet' && part.cell && part.answerFormat) {
        spreadsheetCells.push({
          id: part.id,
          cell: part.cell,
          cellType: part.cellType,
        });
      }
    });

    if (spreadsheetCells.length === 0) {
      console.warn('No spreadsheet cells to extract.');
      setIsExtracting(false);
      return;
    }

    try {
      const cellValues = await fetchCellValues(spreadsheetCells);
      console.log('Extracted cell values:', cellValues);
      questionParts.forEach((part) => {
        if (part.inputType === 'spreadsheet' && part.cell) {
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [part.id]: cellValues[part.id] || { value: '', formula: '' },
          }));
        }
      });
      setIsSaved(false); // Mark as unsaved since answers have been updated
    } catch (error) {
      console.error('Error extracting cell values:', error);
    } finally {
      setIsExtracting(false);
    }
  };

  // Determine if there is at least one spreadsheet input
  const hasSpreadsheetInput = questionParts.some((part) => part.inputType === 'spreadsheet');

  useEffect(() => {
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = '';
    };
  }, []);

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left side: Questions and inputs */}
      <div className="w-1/4 h-full flex flex-col bg-white">
        {/* Scrollable content */}
        <div className="flex-1 p-6 overflow-auto">
          <Button onClick={handleBack} bold className="mb-4">
            Back to Questions
          </Button>
          {/* Top Bar with Toggle and Extract Button */}
          <div className="flex items-center justify-between mb-4">
            {/* Left: Toggle between showing formula and values */}
            <div>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={showFormulas}
                  onChange={(e) => setShowFormulas(e.target.checked)}
                  className="form-checkbox"
                />
                <span className="ml-2 text-gray-700">Show Formulas</span>
              </label>
            </div>
            {/* Right: Extract Cells button */}
            {hasSpreadsheetInput && (
              <Button
                onClick={handleExtractCellValues}
                disabled={isExtracting}
                className="flex items-center px-4 py-2 text-sm"
              >
                <DownloadIcon className="h-5 w-5 mr-2" />
                {isExtracting ? 'Extracting...' : 'Extract Cells'}
              </Button>
            )}
          </div>
            {/* Question Instruction */}
            {questionInstruction && (
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-800">Question Instruction</h2>
              <p className="text-gray-600">{questionInstruction}</p>
            </div>
            )}
            {/* Question Assumptions */}
            {questionAssumptions && (
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-800">Question Assumptions</h2>
                <ul className="list-disc list-inside text-gray-600">
                {questionAssumptions.split('. ').map((assumption, index) => (
                  <li key={index}>{assumption}</li>
                ))}
                </ul>
            </div>
            )}


          {questionParts.map((part, index) => {
            const answerData = answers[part.id];
            const displayValue = showFormulas ? answerData?.formula : answerData?.value;
            return (
              <div key={index} className="mb-6">
                <p className="font-semibold text-gray-700">{part.text}</p>
                {part.inputType === 'user' ? (
                  part.answerFormat === 'number' ? (
                    <input
                      type="number"
                      value={answerData?.value || ''}
                      onChange={(e) => handleInputChange(part.id, e.target.value)}
                      className="mt-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  ) : (
                    <textarea
                      value={answerData?.value || ''}
                      onChange={(e) => handleInputChange(part.id, e.target.value)}
                      className="mt-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  )
                ) : part.cellType === 'single' ? (
                  <p className="mt-2 p-3 border border-gray-300 rounded-lg w-full bg-gray-100 text-gray-600">
                    {displayValue}
                  </p>
                ) : part.cellType === 'row' || part.cellType === 'column' ? (
                  <p className="mt-2 p-3 border border-gray-300 rounded-lg w-full bg-gray-100 text-gray-600">
                    {displayValue && displayValue.join(', ')}
                  </p>
                ) : (
                  displayValue &&
                  displayValue.map((row: any[], rowIndex: number) => (
                    <div key={rowIndex} className="flex space-x-1">
                      {row.map((cell, cellIndex) => (
                        <p
                          key={cellIndex}
                          className="p-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                        >
                          {cell}
                        </p>
                      ))}
                    </div>
                  ))
                )}
              </div>
            );
          })}
        </div>
        {/* Fixed Footer with Save Status */}
        <div className="p-4 bg-white border-t border-gray-200 flex justify-end">
          {/* Right Side: Save Button and Save Status */}
          <div className="flex flex-col items-end space-y-2">
            {!isSaved ? (
              <>
                <Button onClick={handleSave} className="px-4 py-2 text-sm">
                  Save
                </Button>
                <div className="flex items-center text-gray-600 text-sm">
                  <ExclamationCircleIcon className="h-5 w-5 text-yellow-500 mr-1" />
                  <span>Unsaved changes</span>
                </div>
              </>
            ) : (
              <div className="flex items-center text-green-600 text-sm">
                <CheckCircleIcon className="h-5 w-5 text-green-500 mr-1" />
                <span>All changes saved</span>
              </div>
            )}
            {lastSaveTime && (
              <div className="flex items-center text-gray-600 text-sm">
                <span>Last saved at {lastSaveTime.toLocaleTimeString()}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Right side: Embedded Google Spreadsheet */}
      <div className="w-3/4 h-full">
        {sheetLink && (
          <iframe
            src={sheetLink}
            title="Spreadsheet"
            className="w-full h-full border-r border-gray-300"
          />
        )}
      </div>
    </div>
  );
};

export default SpreadsheetQuestion;
