import React, { useContext } from 'react';
import { auth } from '../firebase';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import FullLogo from '../assets/full_logo.png';
import Button from './Button';

interface Props {
  landingPage?: boolean;
}

const NavBar: React.FC<Props> = ({ landingPage }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className="bg-white shadow-sm fixed top-0 left-0 right-0 z-10">
      <div className="flex items-center justify-between px-6 py-3">
        {/* Logo */}
        <div className="flex-shrink-0">
          <img
            src={FullLogo}
            alt="Finterview Logo"
            className="h-8 cursor-pointer"
            onClick={() => navigate('/')}
          />
        </div>

        {/* Conditional Rendering Based on User Authentication and landingPage */}
        <div>
          {user ? (
            <>
              {landingPage ? (
                <Button
                  onClick={() => navigate('/dashboard')}
                  className="mr-4"
                >
                  Go to Dashboard
                </Button>
              ): 
              <span className="mr-4 text-gray-700">{user.name}</span>
              }
             
              <Button onClick={handleLogout} inverted>
                Logout
              </Button>
            </>
          ) : (
            <>
            <Button 
              className="mr-4"
              onClick={() => navigate('/signup')}>
                Sign Up
              </Button>
              <Button
                onClick={() => navigate('/login')}
                inverted
              >
                Login
              </Button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
