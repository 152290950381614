import Question from "../../types/Question";

const ThreeStatements: Question[] = [
    {
        questionNumber: 1,
        questionType: 'Spreadsheet',
        spreadsheetLink: "https://docs.google.com/spreadsheets/d/1iN3v5wfBn9EHbRVZiHwWyjGfv4dt2upGykClxnppdwk/edit?usp=sharing",
        questionInstruction: "You are modelling the 3 Statements for CerealCo, a breakfast foods manufacturer. The cells are unlinked.",
        questionAssumptions: "In FY2019, total revenue for Cereal Corp was $10mm, and revenue has grown, and will grow consistently by 10% a year. Cost of Goods Sold was at 50% untill 2021, after which a renewed packaging contract increased COGS to 55% of sales.  Operating expenses are a flat $1mm a year. SG&A is minimal. Cereal Corp is paying $1mm a year on loans till 2024, then nothing thereafter as debt is paid off. Accounts Recievable is based on Days Sales Outstanding (DSO) of 45 days. (Revenue/ 365)*DSO Assume an Inventory turnover of 6x (Inventory = COGS/ Inventory Turnover). Accounts Payable is based on a 30 Days Payable Outstanding (DPO). Assume straight line depreciation of an initial $50mm of PP&E over 20 years. The Corporate Tax Rate is 25%. No new Equity is issued over the timeframe.  Cereal Corp pays dividends of 50% of NI and purchases back 6mn of stock in 2023",
        questionParts: [
            {
                id: 1,
                text: "Total Revenue",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C16:J16',
                cellType: 'row',
            },
            {
                id: 2,
                text: "Cost of Goods Sold",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C17:J17',
                cellType: 'row',
            },
            {
                id: 3,
                text: "Gross Profit",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C18:J18',
                cellType: 'row',
            },
            {
                id: 4,
                text: "Operating Expenses",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C19:J19',
                cellType: 'row',
            },
            {
                id: 5,
                text: "Operating Income",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C20:J20',
                cellType: 'row',
            },
            {
                id: 6,
                text: "SG&A",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C21:J21',
                cellType: 'row',
            },
            {
                id: 7,
                text: "Depreciation",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C22:J22',
                cellType: 'row',
            },
            {
                id: 8,
                text: "Interest Income/ (Expense)",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C23:J23',
                cellType: 'row',
            },
            {
                id: 9,
                text: "Pre-Tax Income",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C24:J24',
                cellType: 'row',
            },
            {
                id: 10,
                text: "Tax",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C25:J25',
                cellType: 'row',
            },
            {
                id: 11,
                text: "Net Income",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C26:J26',
                cellType: 'row',
            },
            {
                id: 12,
                text: "Cash",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C31:J31',
                cellType: 'row',
            },
            {
                id: 13,
                text: "Accounts Receivable",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C32:J32',
                cellType: 'row',
            },
            {
                id: 14,
                text: "Inventory & Other",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C33:J33',
                cellType: 'row',
            },
            {
                id: 15,
                text: "Net PP&E, Goodwill & Intangibles",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C34:J34',
                cellType: 'row',
            },
            {
                id: 16,
                text: "Total Assets",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C35:J35',
                cellType: 'row',
            },
            {
                id: 17,
                text: "Accounts Payable",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C38:J38',
                cellType: 'row',
            },
            {
                id: 18,
                text: "Total Debt",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C39:J39',
                cellType: 'row',
            },
            {
                id: 19,
                text: "Total Liabilities",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C40:J40',
                cellType: 'row',
            },
            {
                id: 20,
                text: "Common Shareholders' Equity",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C42:J42',
                cellType: 'row',
            },
            {
                id: 21,
                text: "Total Equity",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C43:J43',
                cellType: 'row',
            },
            {
                id: 22,
                text: "Total Liabilites + Equity",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C45:J45',
                cellType: 'row',
            },
            {
                id: 23,
                text: "Net income",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C49:J49',
                cellType: 'row',
            },
            {
                id: 24,
                text: "Depreciation",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C50:J50',
                cellType: 'row',
            },
            {
                id: 25,
                text: "Accounts Recievable",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C51:J51',
                cellType: 'row',
            },
            {
                id: 26,
                text: "Accounts Payable",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C52:J52',
                cellType: 'row',
            },
            {
                id: 27,
                text: "Inventory & Other",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C53:J53',
                cellType: 'row',
            },
            {
                id: 28,
                text: "Taxes Payable",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C54:J54',
                cellType: 'row',
            },
            {
                id: 29,
                text: "Cash Flow from Operations",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C55:J55',
                cellType: 'row',
            },
            {
                id: 30,
                text: "CapEx",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C58:J58',
                cellType: 'row',
            },
            {
                id: 31,
                text: "Cash Flow from Investing",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C60:J60',
                cellType: 'row',
            },
            {
                id: 32,
                text: "Change in Debt",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C63:J63',
                cellType: 'row',
            },
            {
                id: 33,
                text: "Common Dividends",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C64:J64',
                cellType: 'row',
            },
            {
                id: 34,
                text: "Stock Repurchases",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C65:J65',
                cellType: 'row',
            },
            {
                id: 35,
                text: "Other Items",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C66:J66',
                cellType: 'row',
            },
            {
                id: 36,
                text: "Cash Flow from Financing",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C67:J67',
                cellType: 'row',
            },
            {
                id: 37,
                text: "Net Change in Cash",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C71:J71',
                cellType: 'row',
            },
        ],
        requiresPreviousQuestionCompleted: true,
        questionTitle: "3 Statements Test "
    },
];

export default ThreeStatements;
