import Question from "../../types/Question";

const TechHubCaseStudy: Question[] = [
  {
  questionNumber: 1,
  questionType: 'Spreadsheet',
  spreadsheetLink: "https://docs.google.com/spreadsheets/d/1c8u3Jhj2CfVss_VSt5r-HWGuFznO_uSe8LsyjDOzv_U/edit?gid=183992274#gid=183992274",
  questionInstruction: "",
  questionAssumptions: "",
  questionParts: [
    {
      id: 1,
      text: "You are an analyst at Tech Hub, who produce smartphones, tablets and accessories for electronic devices. Performance reviews are upcoming for Tech Hub’s sales representatives. In July, sales representatives were told of bonuses and promotions available to top performing sales representatives. Please rank sales representatives and explain who deserves the bonus. Please determine who is the lowest performing sales representative.",
      answerFormat: 'string',
      inputType: 'user',
    }
  ],
  requiresPreviousQuestionCompleted: true,
  questionTitle: "Tech Hub Sales Performance Review"
  },
  {
  questionNumber: 2,
  questionType: 'Spreadsheet',
  spreadsheetLink: "https://docs.google.com/spreadsheets/d/1xta6OdogkaQp0VvKe5dT-xVBWCKGtsSDeV-sislobQk/edit?gid=1153653107#gid=1153653107",
  questionInstruction: "",
  questionAssumptions: "",
  questionParts: [
    {
      id: 1,
      text: "John Smith has the lowest net profit of all sales reps. What happened? ",
      answerFormat: 'string',
      inputType: 'user',
    }
  ],
  requiresPreviousQuestionCompleted: true,
  questionTitle: "Reason for Low Net Profit"
  },
  {
  questionNumber: 3,
  questionType: 'Spreadsheet',
  spreadsheetLink: "https://docs.google.com/spreadsheets/d/1G1mP_t7EzqfdTjW5VwTn0bmqis-0LgrmAqEqM7m-b4Y/edit?gid=1738159181#gid=1738159181",
  questionInstruction: "",
  questionAssumptions: "",
  questionParts: [
    {
      id: 1,
      text: "Can you identify the lowest performing product? Why?",
      answerFormat: 'string',
      inputType: 'user',
    }
  ],
  requiresPreviousQuestionCompleted: true,
  questionTitle: "Lowest Performing Product"
  },
  {
  questionNumber: 4,
  questionType: 'Spreadsheet',
  spreadsheetLink: "https://docs.google.com/spreadsheets/d/1hokQlwwb0aPtjH2vKgFt9OXr4BoLq1_8ODxngisnS9k/edit?usp=sharing",
  questionInstruction: "",
  questionAssumptions: "",
  questionParts: [
    {
      id: 1,
      text: "Can you identify the lowest performing region and why?",
      answerFormat: 'string',
      inputType: 'user',
    }
  ],
  requiresPreviousQuestionCompleted: true,
  questionTitle: "Lowest Performing Region"
  },
]
  
export default TechHubCaseStudy;
  